import React from "react";

function OurHistory(props) {
  return (
    <div className="bg-light-v">
      <div className="col-lg-12">
        <div className="col-lg-12 row mx-auto text-center py--80 align-items-start justify-content-center">
          <div className="col-12 upper">
            <h5 className="green-heading">OUR HISTORY</h5>
          </div>
          <div className="col-lg-10 col-md-9 col-12 mx-auto mt-md-4 mt-2 mb-2 mb-lg-5">
            <p className="black-heading">
              DEVELOPMENT HISTORY OF DIGIMARK DEVELOPERS
            </p>
          </div>
          <div className="row">
            <div className="col-lg-3 darker p4 mb-lg-4 text-left">
              2016 - 2017
            </div>
            <div className="col-lg-9 para-d font-weight-light linee-h-2 p3 p3-14 mb-lg-4 text-left">
              Our values are the guiding principles upon which NanoSoft was
              founded and how we strive to conduct our business on a daily
              basis.
            </div>
            <div className="col-lg-3 darker p4 mb-lg-4 text-left">
              2018 - 2019
            </div>
            <div className="col-lg-9 para-d font-weight-light linee-h-2 p3 p3-14 mb-lg-4 text-left">
              Our values are the guiding principles upon which NanoSoft was
              founded and how we strive to conduct our business on a daily
              basis.
            </div>
            <div className="col-lg-3 darker p4 mb-lg-4 text-left">
              2020 - 2021
            </div>
            <div className="col-lg-9 para-d font-weight-light linee-h-2 p3 p3-14 mb-lg-4 text-left">
              Our values are the guiding principles upon which NanoSoft was
              founded and how we strive to conduct our business on a daily
              basis.
            </div>
            <div className="col-lg-3 darker p4 mb-lg-4 text-left">
              2021 - Present
            </div>
            <div className="col-lg-9 para-d font-weight-light linee-h-2 p3 p3-14 mb-lg-4 text-left">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurHistory;
