import React from "react";
import Communication from "../../../assets/ic_effective _communication.png";
import Scalibility from "../../../assets/ic_scalability.png";
import Effectiveness from "../../../assets/ic_cost_effectiveness.png";
import StopShop from "../../../assets/ic_one_shop.png";
import AgileDevelopment from "../../../assets/ic_agile_development.png";
import TechnicalExperties from "../../../assets/ic_technical_expertise.png";
function WhyUs(props) {
  return (
    <div className="bg-white">
      <div className="container">
        <div className="row pt--80">
          <div className="col-lg-12 pb-3 mb-2">
            <div className="font-clr-blue row mx-auto justify-content-between">
              <h6 className="green-heading text-left w-100">WHY CHOOSE US</h6>
              <div className="px-0 col-lg-6 col-md-6 text-left">
                <h1 className="black-heading mb-2">
                  6 Reasons to Partner With Us
                </h1>
              </div>
              <div className="col-lg-1 col-md-1 px-0"></div>
              <div className="para-d p--15 px-0 col-lg-5 col-md-5 low-op text-left lh-15 mt-2">
                <p className="mb-0 description para-d">
                  Here are 6 reasons why you should choose us to build your
                  infrastructure, support your people and systems, as well as
                  advise you on projects that will reduce your risk.
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row mx-auto">
              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={Communication} alt="communication" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  Effective Communication
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  Truthful communication plays an important role in the Software
                  development process. Fortunately, we know both. We would like
                  to fully integrate our clients into the development process.
                  We update our clients regularly for each activity.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={Scalibility} alt="Scalibility" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  Scalability
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  With a scalable system, we prevent system downtime while
                  ensuring a flawless user experience. We offer and recommend
                  our customers to choose micro services architecture to achieve
                  scalability and fault tolerance.
                </p>
              </div>

              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={Effectiveness} alt="Effectiveness" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  Cost-Effectiveness
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  We deliver highly cost-effective business solutions. Highly
                  educated staff, better-improved management, and a more
                  economical way of improving enable us to provide business
                  solutions that come with the majority of return on investment.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={StopShop} alt="StopShop" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  One-Stop Shop
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  We handle all aspects of your Software infrastructure
                  including Web Development, App Development, Blockchain, Cloud
                  & DevOps, Technology Consulting, Digital Marketing, Branding
                  and any other related technology needs.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={AgileDevelopment} alt="AgileDevelopment" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  Agile Development
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  With our flexible scrum-based agile methodology, we share
                  iterative builds fortnight with our clients, which gives
                  plenty of time to self-evaluate and make changes. We’re here
                  for ongoing and long-term support and maintenance.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 bg-white text-center why-us-card">
                <div class="overlays text-left">
                  <img src={TechnicalExperties} alt="TechnicalExperties" />
                </div>
                <h5 className="sub-black-heading text-left my-2">
                  Technical Expertise
                </h5>
                <p className="description para-d mt-2 align-items-center text-left">
                  We are a digital transformation company that provides
                  state-of-the-art engineering solutions. In addition to
                  bringing in-depth knowledge to specific areas of technology,
                  we are also fully prepared to help you successfully bring your
                  product to market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
