import React, { useState } from "react";

function Joining(props) {
  const [tabs, setTabs] = useState(1);

  return (
    <div className="bg-light-v">
      <div className="col-lg-11 mx-auto">
        <div className="py--80" id="careers">
          <div className="row mx-auto text-center pb-xxl-5">
            <div className="col-12 upper font-clr-green mb-3 p3">
              JOINING POCCESS
            </div>
            <div className="col-12 upper p9 p-9 darker">
              HOW TO GET INTO DIGIMARK DEVELOPERS
            </div>
            <div className="col-12 para-d font-weight-light mt-2 p3 p3-12 mb-5">
              Feel fulfilled. Have fun. Help us to shape the future.
            </div>
          </div>
          <div className="row mx-auto text-left">
            <div className="my-xl-4 my-2 col-xl col-md col-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64.133 "
                height="70"
                viewBox="0 0 64.133 80.48"
                onClick={() => setTabs(1)}
                className="pointer svg-resp"
              >
                <g
                  id="noun_Resume_2768441"
                  className={`${tabs == 1 ? "fill-green" : "un-fill"}`}
                  transform="translate(-13)"
                >
                  <path
                    id="Path_6022"
                    data-name="Path 6022"
                    d="M27.257,39.532a1.258,1.258,0,0,0,1.258-1.257v-4.2a3.814,3.814,0,0,1,2.516-3.741,7.594,7.594,0,0,1-2.073-1.761A6.327,6.327,0,0,0,26,34.079v4.195a1.258,1.258,0,0,0,1.257,1.258Z"
                    transform="translate(-4.825 -10.609)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6023"
                    data-name="Path 6023"
                    d="M48.514,34.079v4.195a1.258,1.258,0,0,0,2.515,0V34.079a6.327,6.327,0,0,0-2.958-5.5A7.594,7.594,0,0,1,46,30.338a3.814,3.814,0,0,1,2.516,3.741Z"
                    transform="translate(-12.25 -10.609)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6024"
                    data-name="Path 6024"
                    d="M37.03,23.06A5.03,5.03,0,1,0,32,18.03a5.03,5.03,0,0,0,5.03,5.03Zm0-7.545a2.515,2.515,0,1,1-2.515,2.515A2.515,2.515,0,0,1,37.03,15.515Z"
                    transform="translate(-7.054 -4.826)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6025"
                    data-name="Path 6025"
                    d="M73.36,0H16.773A3.777,3.777,0,0,0,13,3.773V76.708a3.777,3.777,0,0,0,3.773,3.773H73.36a3.777,3.777,0,0,0,3.773-3.773s0-28.735,0-46.969V3.773A3.777,3.777,0,0,0,73.36,0Zm1.258,76.708a1.259,1.259,0,0,1-1.258,1.257H16.773a1.259,1.259,0,0,1-1.258-1.257V3.773a1.259,1.259,0,0,1,1.258-1.258H73.36a1.259,1.259,0,0,1,1.258,1.258Z"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6026"
                    data-name="Path 6026"
                    d="M86.264,26H64.258a1.258,1.258,0,1,0,0,2.515H86.264a1.258,1.258,0,1,0,0-2.515Z"
                    transform="translate(-18.562 -9.652)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6027"
                    data-name="Path 6027"
                    d="M86.264,18H64.258a1.258,1.258,0,1,0,0,2.515H86.264a1.258,1.258,0,1,0,0-2.515Z"
                    transform="translate(-18.562 -6.682)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6028"
                    data-name="Path 6028"
                    d="M72.528,57H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -21.161)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6029"
                    data-name="Path 6029"
                    d="M72.528,65H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -24.131)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6030"
                    data-name="Path 6030"
                    d="M72.528,73H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -27.101)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6031"
                    data-name="Path 6031"
                    d="M72.528,85H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -31.556)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6032"
                    data-name="Path 6032"
                    d="M72.528,93H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -34.526)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6033"
                    data-name="Path 6033"
                    d="M47.378,101H27.258a1.258,1.258,0,1,0,0,2.515h20.12a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-4.826 -37.496)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6034"
                    data-name="Path 6034"
                    d="M86.264,34H64.258a1.258,1.258,0,0,0,0,2.515H86.264a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-18.562 -12.622)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6035"
                    data-name="Path 6035"
                    d="M74.318,44.515a1.258,1.258,0,0,0,0-2.515H64.258a1.258,1.258,0,0,0,0,2.515Z"
                    transform="translate(-18.562 -15.592)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6036"
                    data-name="Path 6036"
                    d="M91.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,1,0,0-2.515Z"
                    transform="translate(-28.586 -41.208)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6037"
                    data-name="Path 6037"
                    d="M98.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,0,0,0-2.515Z"
                    transform="translate(-31.185 -41.208)"
                    fill="#81b441"
                  />
                  <path
                    id="Path_6038"
                    data-name="Path 6038"
                    d="M84.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,1,0,0-2.515Z"
                    transform="translate(-25.987 -41.208)"
                    fill="#81b441"
                  />
                </g>
              </svg>
            </div>
            <div className="my-xl-4 my-2 col-xl col-md col-2">
              <svg
                onClick={() => setTabs(2)}
                id="noun_test_1221430"
                xmlns="http://www.w3.org/2000/svg"
                width="63.805 "
                height="70"
                viewBox="0 0 63.805 80.644"
                className="pointer svg-resp"
              >
                <g
                  className={`${tabs == 2 ? "fill-green" : "un-fill"}`}
                  id="Group_3691"
                  data-name="Group 3691"
                >
                  <path
                    id="Path_6011"
                    data-name="Path 6011"
                    d="M47.09,958.362a1.779,1.779,0,0,0-.888.229l-3.923,2.177H42.25l-3.522,1.947H25.612c-5.666,0-10.424,4.4-10.424,9.909V1029.1c0,5.5,4.758,9.908,10.424,9.908H68.569c5.666,0,10.424-4.408,10.424-9.908V972.623c0-5.508-4.758-9.909-10.424-9.909H55.453L51.9,960.767l-3.923-2.176a1.88,1.88,0,0,0-.888-.229Zm-21.478,8.019H38.728l3.522,1.976,3.952,2.177a1.835,1.835,0,0,0,1.776,0l3.923-2.177,3.551-1.976H68.569a6.523,6.523,0,0,1,6.759,6.243V1029.1a6.53,6.53,0,0,1-6.759,6.243H25.612a6.53,6.53,0,0,1-6.759-6.243V972.623A6.523,6.523,0,0,1,25.612,966.381Z"
                    transform="translate(-15.188 -958.362)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6012"
                    data-name="Path 6012"
                    d="M29.034,979.512a1.833,1.833,0,1,0,0,3.666H53.969a1.833,1.833,0,1,0,0-3.666Z"
                    transform="translate(-16.19 -960.13)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6013"
                    data-name="Path 6013"
                    d="M29.034,992.532a1.833,1.833,0,1,0,0,3.665H53.969a1.833,1.833,0,1,0,0-3.665Z"
                    transform="translate(-16.19 -961.218)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6014"
                    data-name="Path 6014"
                    d="M29.034,1005.56a1.914,1.914,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.9,1.9,0,0,0,.709-.132,1.833,1.833,0,0,0-.709-3.534Z"
                    transform="translate(-16.19 -962.307)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6015"
                    data-name="Path 6015"
                    d="M29.034,1018.58a1.914,1.914,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.892,1.892,0,0,0,.709-.132,1.833,1.833,0,0,0,0-3.4,1.831,1.831,0,0,0-.709-.132Z"
                    transform="translate(-16.19 -963.395)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6016"
                    data-name="Path 6016"
                    d="M29.034,1031.6a1.911,1.911,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.9,1.9,0,0,0,.709-.133,1.833,1.833,0,0,0,0-3.4,1.818,1.818,0,0,0-.709-.132Z"
                    transform="translate(-16.19 -964.483)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6017"
                    data-name="Path 6017"
                    d="M69.023,976.593a1.832,1.832,0,0,0-1.277.514L66.4,978.368l-1.344-1.261a1.833,1.833,0,1,0-2.508,2.673l1.174,1.1-1.174,1.1a1.833,1.833,0,1,0,2.508,2.673l1.344-1.261,1.344,1.261a1.833,1.833,0,1,0,2.508-2.673l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z"
                    transform="translate(-19.096 -959.885)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6018"
                    data-name="Path 6018"
                    d="M69.023,1029.23a1.827,1.827,0,0,0-1.277.514L66.4,1031l-1.344-1.261a1.836,1.836,0,0,0-1.32-.517,1.92,1.92,0,0,0-.705.159,1.833,1.833,0,0,0-.484,3.031l1.174,1.1-1.174,1.1a1.833,1.833,0,0,0,1.189,3.19,1.777,1.777,0,0,0,.712-.126,1.829,1.829,0,0,0,.608-.391l1.344-1.261,1.344,1.261a1.833,1.833,0,0,0,1.319.517,1.9,1.9,0,0,0,.705-.159,1.833,1.833,0,0,0,.484-3.031l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z"
                    transform="translate(-19.096 -964.285)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6019"
                    data-name="Path 6019"
                    d="M69.023,1003.549a1.834,1.834,0,0,0-1.277.515l-1.344,1.261-1.344-1.261a1.833,1.833,0,0,0-1.32-.517,1.907,1.907,0,0,0-.705.159,1.833,1.833,0,0,0-.484,3.031l1.174,1.1-1.174,1.1a1.833,1.833,0,0,0,1.189,3.19,1.774,1.774,0,0,0,.712-.126,1.837,1.837,0,0,0,.608-.391l1.344-1.261,1.344,1.261a1.831,1.831,0,0,0,1.319.517,1.9,1.9,0,0,0,.705-.159,1.833,1.833,0,0,0,.484-3.031l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z"
                    transform="translate(-19.096 -962.138)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6020"
                    data-name="Path 6020"
                    d="M69.661,988.879a1.833,1.833,0,0,0-1.642.892l-1.827,2.967a1.833,1.833,0,0,0-3.158,1.7l.889,3.116a1.833,1.833,0,0,0,3.323.458l3.895-6.323a1.833,1.833,0,0,0-1.479-2.815Z"
                    transform="translate(-19.18 -960.912)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6021"
                    data-name="Path 6021"
                    d="M69.659,1015.29a1.833,1.833,0,0,0-1.641.894l-1.829,2.973a1.833,1.833,0,0,0-3.156,1.706l.889,3.116a1.833,1.833,0,0,0,3.323.457l3.895-6.333a1.833,1.833,0,0,0-1.481-2.814Z"
                    transform="translate(-19.18 -963.12)"
                    fill="#1d4354"
                  />
                </g>
              </svg>
            </div>
            <div className="my-xl-4 my-2 col-xl col-md col-2">
              <svg
                id="noun_discussion_2315300"
                xmlns="http://www.w3.org/2000/svg"
                width="91.457 "
                height="70"
                viewBox="0 0 91.457 80.701"
                onClick={() => setTabs(3)}
                className="pointer svg-resp"
              >
                <g
                  className={`${tabs == 3 ? "fill-green" : "un-fill"}`}
                  id="Group_3694"
                  data-name="Group 3694"
                >
                  <path
                    id="Path_6047"
                    data-name="Path 6047"
                    d="M32.356,135.044,27.5,134.3l-1.261-13.351a10.759,10.759,0,1,0-21.473,1.372L6.1,136.305a10.722,10.722,0,0,0,6.9,9.383,7,7,0,0,0,3.375.7l10.2.482a1.832,1.832,0,0,0,1.929-1.743,1.855,1.855,0,0,0-1.743-1.928l-10.31-.482h-.185a3.218,3.218,0,0,1-1.669-.334.527.527,0,0,0-.223-.074,7.13,7.13,0,0,1-4.636-6.268l-1.3-14.019a7.077,7.077,0,1,1,14.13-.816L23.9,135.193v.63a1.81,1.81,0,0,0,1.558,1.854l6.342,1a1.56,1.56,0,0,1,1.335,1.558V155.7a1.854,1.854,0,1,0,3.709,0V140.237A5.362,5.362,0,0,0,32.356,135.044Z"
                    transform="translate(-4.743 -76.855)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6048"
                    data-name="Path 6048"
                    d="M19.143,66.785A9.643,9.643,0,1,0,9.5,57.143,9.671,9.671,0,0,0,19.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,19.143,51.172Z"
                    transform="translate(-7.736 -37.004)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6049"
                    data-name="Path 6049"
                    d="M186.755,110.864a10.763,10.763,0,0,0-11.386,10.013L174.109,134.3l-4.858.742a5.261,5.261,0,0,0-4.45,5.192V155.7a1.86,1.86,0,0,0,1.854,1.854,1.836,1.836,0,0,0,1.854-1.854V140.237a1.588,1.588,0,0,1,1.335-1.558l6.342-1a1.81,1.81,0,0,0,1.558-1.854v-.556l1.335-14.093a7.076,7.076,0,0,1,14.13.779l-1.335,14.093a7.078,7.078,0,0,1-4.6,6.193.527.527,0,0,1-.223.074,3.218,3.218,0,0,1-1.669.334H185.2l-10.31.482a1.83,1.83,0,0,0-1.743,1.928,1.808,1.808,0,0,0,1.929,1.743l10.2-.482a7.09,7.09,0,0,0,3.375-.7,10.8,10.8,0,0,0,6.9-9.346l1.335-14.093A10.76,10.76,0,0,0,186.755,110.864Z"
                    transform="translate(-105.44 -76.855)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6050"
                    data-name="Path 6050"
                    d="M204.143,66.785a9.643,9.643,0,1,0-9.643-9.643A9.671,9.671,0,0,0,204.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,204.143,51.172Z"
                    transform="translate(-124.125 -37.004)"
                    fill="#1d4354"
                  />
                  <path
                    id="Path_6051"
                    data-name="Path 6051"
                    d="M114.751,44.419V21.166a1.979,1.979,0,0,0-1.966-1.966H78.666A1.979,1.979,0,0,0,76.7,21.166V44.419a1.979,1.979,0,0,0,1.966,1.966H88.2l6.231,6.231a1.793,1.793,0,0,0,2.6,0l6.231-6.231h9.531A1.955,1.955,0,0,0,114.751,44.419Zm-3.672-1.706h-8.6a1.74,1.74,0,0,0-1.3.556l-5.452,5.452-5.452-5.489a1.889,1.889,0,0,0-1.3-.556h-8.6V22.909h30.708Z"
                    transform="translate(-50.013 -19.2)"
                    fill="#1d4354"
                  />
                </g>
              </svg>
            </div>
            <div className="my-xl-4 my-2 col-xl col-md col-2">
              <svg
                id="onboarding"
                xmlns="http://www.w3.org/2000/svg"
                width="116.819" height="68.77" viewBox="0 0 116.819 68.77"
                onClick={() => setTabs(4)}
                className="pointer svg-resp"
              >
                <g
                  className={`${tabs == 4 ? "fill-green" : "un-fill"}`}
                  id="Group_8501" data-name="Group 8501" transform="translate(15895.268 12253.754)"
                >
                  <path
                    id="Path_25049"
                    data-name="Path 25049"
                    d="M14.89,148.139s13.425,1.193,22.67,0a158.8,158.8,0,0,0,15.661-5.967,26.6,26.6,0,0,1,15.253-2.3c4.026.6,13.78.974,17.71,1.254,3.5.251,5.967,3.131,3.579,5.966"
                    transform="translate(-15908.531 -12391.824)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25050"
                    data-name="Path 25050"
                    d="M224.225,172.715s-22.226,1.342-33.708-2.237S175,165.11,168.442,165.407s-10.738-.3-14.766,2.535-4.923,4.027-7.906,5.966-8.352,3.727-8.5,5.518-1.043,1.642.448,3.729,4.177,1.941,6.863,1.791,7.607-.9,9.4-2.834,5.071-4.027,6.86-3.877a38.468,38.468,0,0,1,8.5,1.938c2.088.9,10.888,3.28,14.916,6.861s4.177,3.8,5.519,5,8.948,7.755,9.246,11.185,1.193,5.071-1.716,6.785-3.281,1.716-4.4.522-2.684-4.026-4.922-5.965-9.546-7.98-9.546-7.98"
                    transform="translate(-16004.363 -12412.074)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25051"
                    data-name="Path 25051"
                    d="M280.18,349.216s12.827,9.322,12.827,12.976-2.088,3.877-3.131,4.176a6.906,6.906,0,0,1-5.817-1.491c-2.536-1.865-10.664-7.906-10.664-7.906"
                    transform="translate(-16111.626 -12556.509)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25052"
                    data-name="Path 25052"
                    d="M364.885,385.151c.224.374,4.548.374,5.669-1.491s1.714-2.76,1.789-5.221"
                    transform="translate(-16183.505 -12579.468)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25053"
                    data-name="Path 25053"
                    d="M249.117,398.459s-1.492,4.026-5,3.876-7.755-2.534-9.322-3.876c0,0,.842-4.025-.723-5.515s-4.593-3.646-5.512-.891c-.975,2.918-1.445,4.242-1.445,6.63s1.342,2.312,3.208,3.28,3.951,1.119,6.338-1.193"
                    transform="translate(-16075.268 -12589.12)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25054"
                    data-name="Path 25054"
                    d="M194.836,359.5s.6-3.655,2.015-4.625,4.1-1.192,4.624,1.938,1.259,4.8,1.112,5.841a27.416,27.416,0,0,0-1.378,3.646,19.646,19.646,0,0,0-.478,4.756s-6.307.762-7.084-3.579c-.708-3.931.52-5.667,1.191-7.977Zm0,0"
                    transform="translate(-16048.803 -12560.453)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25055"
                    data-name="Path 25055"
                    d="M152.647,347.778c-.141.188-1.193,4.253,1.864,5.742a6.585,6.585,0,0,0,6.415-.075,87.706,87.706,0,0,1,2.982-9.991s-.075-3.281-2.907-4.1-3.432.075-4.253,1.194-3.205,6.039-4.1,7.233Zm0,0"
                    transform="translate(-16016.534 -12548.508)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25056"
                    data-name="Path 25056"
                    d="M122.343,322.193s1.641-3.727,3.507-4.1,4.324-.744,5.219,2.685.671,3.208-.448,5.146-4.1,7.828-7.532,5-2.684-5.742-2.684-5.742c.82-1.417,1.939-2.983,1.939-2.983Zm0,0"
                    transform="translate(-15991.375 -12531.871)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25057"
                    data-name="Path 25057"
                    d="M18.028,319.295s16.554-.075,23.787,3.655"
                    transform="translate(-15910.996 -12533.001)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_25058"
                    data-name="Path 25058"
                    d="M423.511,319.9s9.955-3.469,26.063-3.023"
                    transform="translate(-16229.564 -12531.074)"
                    fill="none"
                    stroke="#1d4354"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                </g>
              </svg>

              {/* <svg
                id='onboarding'
                xmlns='http://www.w3.org/2000/svg'
                width='91.457 '
                height='70'
                viewBox='0 0 91.457 80.701'
                onClick={() => setTabs(4)}
                className='pointer svg-resp'
              >
                <g
                  className={`${tabs == 4 ? 'fill-green' : 'un-fill'}`}
                  id='Group_3694'
                  data-name='Group 3694'
                >
                  <path
                    id='Path_6047'
                    data-name='Path 6047'
                    d='M32.356,135.044,27.5,134.3l-1.261-13.351a10.759,10.759,0,1,0-21.473,1.372L6.1,136.305a10.722,10.722,0,0,0,6.9,9.383,7,7,0,0,0,3.375.7l10.2.482a1.832,1.832,0,0,0,1.929-1.743,1.855,1.855,0,0,0-1.743-1.928l-10.31-.482h-.185a3.218,3.218,0,0,1-1.669-.334.527.527,0,0,0-.223-.074,7.13,7.13,0,0,1-4.636-6.268l-1.3-14.019a7.077,7.077,0,1,1,14.13-.816L23.9,135.193v.63a1.81,1.81,0,0,0,1.558,1.854l6.342,1a1.56,1.56,0,0,1,1.335,1.558V155.7a1.854,1.854,0,1,0,3.709,0V140.237A5.362,5.362,0,0,0,32.356,135.044Z'
                    transform='translate(-4.743 -76.855)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6048'
                    data-name='Path 6048'
                    d='M19.143,66.785A9.643,9.643,0,1,0,9.5,57.143,9.671,9.671,0,0,0,19.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,19.143,51.172Z'
                    transform='translate(-7.736 -37.004)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6049'
                    data-name='Path 6049'
                    d='M186.755,110.864a10.763,10.763,0,0,0-11.386,10.013L174.109,134.3l-4.858.742a5.261,5.261,0,0,0-4.45,5.192V155.7a1.86,1.86,0,0,0,1.854,1.854,1.836,1.836,0,0,0,1.854-1.854V140.237a1.588,1.588,0,0,1,1.335-1.558l6.342-1a1.81,1.81,0,0,0,1.558-1.854v-.556l1.335-14.093a7.076,7.076,0,0,1,14.13.779l-1.335,14.093a7.078,7.078,0,0,1-4.6,6.193.527.527,0,0,1-.223.074,3.218,3.218,0,0,1-1.669.334H185.2l-10.31.482a1.83,1.83,0,0,0-1.743,1.928,1.808,1.808,0,0,0,1.929,1.743l10.2-.482a7.09,7.09,0,0,0,3.375-.7,10.8,10.8,0,0,0,6.9-9.346l1.335-14.093A10.76,10.76,0,0,0,186.755,110.864Z'
                    transform='translate(-105.44 -76.855)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6050'
                    data-name='Path 6050'
                    d='M204.143,66.785a9.643,9.643,0,1,0-9.643-9.643A9.671,9.671,0,0,0,204.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,204.143,51.172Z'
                    transform='translate(-124.125 -37.004)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6051'
                    data-name='Path 6051'
                    d='M114.751,44.419V21.166a1.979,1.979,0,0,0-1.966-1.966H78.666A1.979,1.979,0,0,0,76.7,21.166V44.419a1.979,1.979,0,0,0,1.966,1.966H88.2l6.231,6.231a1.793,1.793,0,0,0,2.6,0l6.231-6.231h9.531A1.955,1.955,0,0,0,114.751,44.419Zm-3.672-1.706h-8.6a1.74,1.74,0,0,0-1.3.556l-5.452,5.452-5.452-5.489a1.889,1.889,0,0,0-1.3-.556h-8.6V22.909h30.708Z'
                    transform='translate(-50.013 -19.2)'
                    fill='#1d4354'
                  />
                </g>
              </svg> */}
            </div>
          </div>
          <div className="col-lg-12 mt-5">
            <div className="row text-left">
              <div className="col-lg-4 col-md-4 mt-3">
                <button
                  onClick={() => setTabs(1)}
                  className={` ${
                    tabs == 1
                      ? "font-clr-green font-weight-bold active-border"
                      : "font-weight-light darker border-0"
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Apply
                </button>
                <button
                  onClick={() => setTabs(2)}
                  className={` ${
                    tabs == 2
                      ? "font-clr-green font-weight-bold active-border"
                      : "font-weight-light darker border-0"
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Review
                </button>
                <button
                  onClick={() => setTabs(3)}
                  className={` ${
                    tabs == 3
                      ? "font-clr-green font-weight-bold active-border"
                      : "font-weight-light darker border-0"
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Interview
                </button>

                <button
                  onClick={() => setTabs(4)}
                  className={` ${
                    tabs == 4
                      ? "font-clr-green font-weight-bold active-border"
                      : "font-weight-light darker border-0"
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Onboarding
                </button>
              </div>
              <div className="col-lg-8 col-md-8 darker p15 mt-3">
                <p>
                  {tabs == 1
                    ? `Scroll through the open vacancies below and observe for positions that align along with your specific skillset.`
                    : tabs == 2
                    ? `Our expert staff reviews your request. They will check your resume and find the right one for you.`
                    : tabs == 3
                    ? `We have a consultation process based on the ability to identify successful people in our area. Each department has its terms, but usually rounds the recruiter’s phone screen, the hiring manager’s interview, and a combination of technical screens, panel interviews, and team presentations.`
                    : tabs == 4
                    ? `Once you have received the message “Welcome Aboard”, our onboarding experience will polish and plan your professional journey with DigiMark Developers.`
                    : `lorem epsum 5`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joining;
