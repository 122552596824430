import React from "react";
import banner_icon from "../../../assets/about-us/pexels-photo-140983.png";
import ClientsMarquee from "../Expertisesections/ClientMarquee";
function Globalbrand(props) {
  return (
    <div class="bg-story my-0">
      <div className="col-lg-12">
        <div class="text-white py--80">
          <div className="col-lg-7 col-md-10 col-12 mx-auto">
            <h6 class="p3 font-clr-green upper text-center w-100">
              SOLUTIONS WE DELIVER
            </h6>
            <div class="p9 font-weight-bold mx-auto col-12">
              WE WORK WITH GLOBAL BRANDS
            </div>
            <div class="font-weight-light text-center text-justify helvetica op-9 lh-13 mt-3 p25 p--15">
              <p>
                Over the years we have sharpened a unique process that produces
                extraordinary results with amazing efficiency.
              </p>
            </div>
          </div>
          <div className="col-12 mx-auto">
            <ClientsMarquee />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Globalbrand;
