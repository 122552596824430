import React from "react";
import laptop from "../../../assets/About Us Page/Rectangle 83.png";
import laptop_2 from "../../../assets/About Us Page/Rectangle 84.png";
import laptop_3 from "../../../assets/About Us Page/Rectangle 85.png";
import laptop_4 from "../../../assets/About Us Page/Rectangle 87.png";
import laptop_5 from "../../../assets/About Us Page/Rectangle 86.png";
import laptop_6 from "../../../assets/About Us Page/Rectangle 88.png";
function OurValues(props) {
  return (
    <div>
      <div className="col-lg-12 py--80">
        <div className="row bg-white">
          <div className="col-lg-12 col-md-11 mx-auto">
            <div className="row mx-auto text-left">
              <div className="col-lg-4 col-md-5 upper mb-2">
                <p className="black-heading">Our Values</p>
              </div>
              <div className="col-lg-8 col-md-7 para-d description pb--80">
                We wаnt tо орerаte in а wаy оur emрlоyees саn be рrоud оf. Оur
                vаlues serve аs а соmраss in evаluаting орроrtunities аnd
                guiding оur deсisiоn-mаking.
                <div class="mt-3">
                  Оur Vаlues – Соmmitment, Innоvаtiоn, Engаge, аnd Exсellenсe –
                  рrоvide а fоundаtiоn fоr оur соmраny сulture. They serve аs а
                  соmраss in evаluаting орроrtunities, guiding оur
                  deсisiоn-mаking, аnd helрing us see the wоrld the Fоrtum wаy:
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-auto text-center">
          <div className="col-xl-8 col-lg-10 col-md-11 mx-auto bg-light-grey py-4 px-5 br-39 border">
            <div className="row m-auto">
              <div className="col-lg-6 col-md-6 text-left">
                <div className="my-4">
                  <svg
                    id="noun_commitment_3672040"
                    xmlns="http://www.w3.org/2000/svg"
                    width="59.332"
                    height="50"
                    viewBox="0 0 59.332 39.495"
                  >
                    <path
                      id="Path_6080"
                      data-name="Path 6080"
                      d="M25.266,18.179h-9.28L11.43,33.214C18.811,41.532,28.553,48.9,32.41,51.678a1.428,1.428,0,0,0,1.839-2.172l-2.492-2.492-2.848-2.848a1.183,1.183,0,0,1,1.673-1.673l6.633,6.633a1.85,1.85,0,0,0,2.634-2.6l-5.862-6.064a1.2,1.2,0,0,1,.036-1.685,1.182,1.182,0,0,1,1.673.036l.937.961,4.925,5.1,1.317,1.353a1.944,1.944,0,0,0,3.334-1.305,1.971,1.971,0,0,0-.593-1.436l-4.391-4.26-2.9-2.8a1.183,1.183,0,0,1,1.649-1.7l8.5,8.223A1.794,1.794,0,0,0,51,42.944a1.73,1.73,0,0,0,.546-1.317,1.793,1.793,0,0,0-.593-1.305L34.866,25.6a11.075,11.075,0,0,0-5.031,3.038.873.873,0,0,1-.225.19c-4.414,2.86-7.013.546-7.12.451-2.338-2.124-1.222-5.47-.2-7.12A24.94,24.94,0,0,1,25.266,18.179Z"
                      transform="translate(-3.8 -12.459)"
                      fill="#1d4354"
                    />
                    <path
                      id="Path_6081"
                      data-name="Path 6081"
                      d="M34.987,16.132a5.345,5.345,0,0,0-3.833-.083c-2.338.843-5.731,2.9-8.746,7.808-.024.036-1.685,2.789-.214,4.118.036.036,1.4,1.1,4.118-.617.605-.629,3.5-3.418,6.942-3.738a1.183,1.183,0,0,1,.914.309L50.663,39.034c.012,0,.012,0,.012.012a5.039,5.039,0,0,0,2.717-2.1L47.578,21.14Z"
                      transform="translate(-1.904 -12.915)"
                      fill="#1d4354"
                    />
                    <path
                      id="Path_6082"
                      data-name="Path 6082"
                      d="M55.55,15.97c-2.231.154-6.811.629-9.695,1.839a1.357,1.357,0,0,0-.712,1.709L52.168,38.6a1.391,1.391,0,0,0,1.282.89h2.183a1.22,1.22,0,0,0,1.222-1.222V17.18A1.2,1.2,0,0,0,55.55,15.97Z"
                      transform="translate(2.476 -12.872)"
                      fill="#1d4354"
                    />
                    <path
                      id="Path_6083"
                      data-name="Path 6083"
                      d="M6.222,36.938H8.287a1.349,1.349,0,0,0,1.305-.961l5.755-19.034a1.359,1.359,0,0,0-.724-1.638A23.133,23.133,0,0,0,6.27,13.359,1.217,1.217,0,0,0,5,14.582V35.716A1.22,1.22,0,0,0,6.222,36.938Z"
                      transform="translate(-5 -13.358)"
                      fill="#1d4354"
                    />
                  </svg>
                  <div className="my-xxl-3 sub-black-heading">Commitment</div>
                  <div className="my-xxl-3 description para-d">
                    Be accountable, work together as a team and communicate
                    clearly.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-left">
                <div className="my-4">
                  <svg
                    id="noun_innovation_1876197"
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.363"
                    height="50"
                    viewBox="0 0 38.363 48.1"
                  >
                    <g id="Group_3741" data-name="Group 3741">
                      <path
                        id="Path_6084"
                        data-name="Path 6084"
                        d="M25,29.2a1.441,1.441,0,0,0,1.3-.8H23.7A1.441,1.441,0,0,0,25,29.2Z"
                        transform="translate(-5.803 -1)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6085"
                        data-name="Path 6085"
                        d="M28.2,26.6v-.4h.6a.4.4,0,0,0,0-.8h-.6v-.6H21.8v.6h-.6a.4.4,0,0,0,0,.8h.6v.4a.9.9,0,0,0,.9.9h4.6A.9.9,0,0,0,28.2,26.6Z"
                        transform="translate(-5.803 -1)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6086"
                        data-name="Path 6086"
                        d="M20.4,22.6a1.508,1.508,0,0,0,1.5,1.2h6.2a1.508,1.508,0,0,0,1.5-1.2,8.552,8.552,0,0,1,2-4.3,12.149,12.149,0,0,0,3-7.7,9.6,9.6,0,1,0-19.2,0,11.821,11.821,0,0,0,3,7.7A7.8,7.8,0,0,1,20.4,22.6Z"
                        transform="translate(-5.803 -1)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6087"
                        data-name="Path 6087"
                        d="M20.4,31.7h0a10,10,0,0,0-2.1-2.9c-2.4-2.4-1.1-5.9-2.6-8.7a4.141,4.141,0,0,0-.9-1.2,1.618,1.618,0,0,0-2.6,1,16.95,16.95,0,0,0-.1,2.3l-1.2-1.3.4-14.4A1.83,1.83,0,0,0,9,4.7c-1.8.5-1.1,2.4-3.1,10.4C5.6,16.9,6,17.7,7,25.4l5.5,11.8L10.2,47.7a1.141,1.141,0,0,0,1.1,1.4h9.9A1.11,1.11,0,0,0,22.3,48V36.9C22.6,34,20.8,32.5,20.4,31.7Z"
                        transform="translate(-5.803 -1)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6088"
                        data-name="Path 6088"
                        d="M42.2,6.1a1.813,1.813,0,0,0-3.6.3L39,20.9l-1.2,1.3a15.52,15.52,0,0,0-.1-2.3,1.538,1.538,0,0,0-2.5-1c-2.7,2.4-.9,7.2-3.5,9.9a12.329,12.329,0,0,0-2.1,2.9h0L28.7,33c-1.5,2.2-.8,2.6-1,14.9A1.11,1.11,0,0,0,28.8,49h9.9a1.194,1.194,0,0,0,1.1-1.4L37.5,37.1,43,25.4C44.8,10.5,44.5,20.7,42.2,6.1Z"
                        transform="translate(-5.803 -1)"
                        fill="#1d4354"
                      />
                    </g>
                  </svg>
                  <div className="my-xxl-3 sub-black-heading">Innovation</div>
                  <div className="my-xxl-3 description para-d">
                    Think outside the box, challenge and act before others do.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-left">
                <div className="my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="68.233"
                    height="50"
                    viewBox="0 0 68.233 38.413"
                    className="my-2"
                  >
                    <path
                      id="Path_6199"
                      data-name="Path 6199"
                      d="M76.677,229.61c7.984,0,14.456,5.635,14.456,12.586a11.19,11.19,0,0,1-1.281,5.187H63.5a11.19,11.19,0,0,1-1.281-5.187c0-6.951,6.472-12.586,14.456-12.586Zm-21.182,1.871a14.239,14.239,0,0,1,7.353,2,13.9,13.9,0,0,0-3.115,8.72,13.527,13.527,0,0,0,.4,3.25,13.867,13.867,0,0,0,.632,1.937H43.707a10.015,10.015,0,0,1-1.147-4.64C42.56,236.523,48.352,231.481,55.495,231.481Zm0-18.467a8.4,8.4,0,1,1-8.395,8.4A8.4,8.4,0,0,1,55.495,213.014ZM97.859,231.48c7.143,0,12.935,5.043,12.935,11.263a10.015,10.015,0,0,1-1.147,4.64H92.59a13.867,13.867,0,0,0,.632-1.937,13.527,13.527,0,0,0,.4-3.25,13.9,13.9,0,0,0-3.115-8.72A14.24,14.24,0,0,1,97.859,231.48Zm0-18.466a8.4,8.4,0,1,1-8.4,8.4A8.4,8.4,0,0,1,97.859,213.014ZM76.677,208.97a9.383,9.383,0,1,1-9.383,9.383A9.383,9.383,0,0,1,76.677,208.97Z"
                      transform="translate(-42.56 -208.97)"
                      fill="#1d4354"
                    />
                  </svg>

                  <div className="my-xxl-3 sub-black-heading">Engage</div>
                  <div className="my-xxl-3 description para-d">
                    Creating a positive place to work and supporting ethical
                    initiatives.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-left">
                <div className="my-4 my-3">
                  <svg
                    id="noun_perfect_1705233"
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="50"
                    viewBox="0 0 53 62.254"
                    className="my-2"
                  >
                    <g
                      id="Group_3743"
                      data-name="Group 3743"
                      transform="translate(0 12.485)"
                    >
                      <path
                        id="Path_6090"
                        data-name="Path 6090"
                        d="M25.576,47.2,14.5,39.294a1.582,1.582,0,0,0-1.839,2.575l11.075,7.91A1.582,1.582,0,0,0,25.576,47.2Z"
                        transform="translate(-7.254 -39.001)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6091"
                        data-name="Path 6091"
                        d="M23.737,70.294,12.662,78.2A1.582,1.582,0,0,0,14.5,80.78l11.075-7.91a1.582,1.582,0,0,0-1.839-2.575Z"
                        transform="translate(-7.254 -45.478)"
                        fill="#1d4354"
                      />
                      <path
                        id="Path_6092"
                        data-name="Path 6092"
                        d="M21.03,60.582A1.582,1.582,0,0,0,19.448,59H7.582a1.582,1.582,0,1,0,0,3.164H19.448A1.582,1.582,0,0,0,21.03,60.582Z"
                        transform="translate(-6 -43.18)"
                        fill="#1d4354"
                      />
                    </g>
                    <path
                      id="Path_6093"
                      data-name="Path 6093"
                      d="M60.3,17.587l-3.374,9.558-.242-.163L57.975,5.544s-4.082-3.823-4.757,5.3l-.651,9.628C50.1,6.148,50.063,5.962,48.292,2.008c0,0-3.374-.478-2.766,5.926l2.031,12.9.36.371-.434-.275-8.366.956-4.049,9.176s4.234,3.154,5.853-2.94c0,0,2.126,2.438,4.454.861l1.518,5.735-2.581,4.874-3.542-1.577S39.96,31.255,35,33.979l2.328,8.912,3.8,5.234s1.417,7.311,5.061,10.9c0,0,.181,1.561.4,2.867s.235,2.366,2.024,2.366h6.73c1.789,0,1.727-2.049,1.859-4.575l1.077-20.586L65.06,16.87S62.226,13.429,60.3,17.587Z"
                      transform="translate(-12.06 -2)"
                      fill="#1d4354"
                    />
                  </svg>
                  <div className="my-xxl-3 sub-black-heading">Excellence</div>
                  <div className="my-xxl-3 description para-d">
                    Always deliver exceptional quality, accomplish and improve.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurValues;
