import React from "react";
import Requirements from "../../../assets/ic_requirement_gathering.png";
import UiUX from "../../../assets/ic_uiux_design.png";
import Prototype from "../../../assets/ic_prototype.png";
import SoftwareDevelopment from "../../../assets/ic_software_development.png";
import Qa from "../../../assets/ic_qa.png";
import Deployment from "../../../assets/ic_deployment.png";
import SupportMaintenance from "../../../assets/ic_support_maintenance.png";

function Process(props) {
  return (
    <div className="bg-light-v">
      <div className="container">
        <div className="row justify-content-center py--80">
          <div className="col-12 col-lg-12 col-md-10 pb-4">
            <h6 className="green-heading text-center w-100">
              Process We Follow
            </h6>
            <div className="font-clr-blue row mx-auto">
              <h1 className="mx-auto black-heading mb-0">
                OUR PROVEN PROCESS, PRODUCES RESULTS
              </h1>
            </div>
          </div>
          <div className="row mx-auto">
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">01</div>
              <div class="overlays text-left">
                <img src={Requirements} alt="Requirements" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Requirement Gathering
              </h5>
              <p className="my-2 align-items-center description text-left">
                We make sure the requirements collecting can be obvious or
                hidden, recognized or unknown, expected or unexpected from the
                client's perspective.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">02</div>
              <div class="overlays text-left">
                <img src={UiUX} alt="UiUX" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                UI/UX Design
              </h5>
              <p className="my-2 align-items-center description text-left">
                We ensure the end-user of your product receives the best user
                experience. We conduct comprehensive consumer analysis and
                assessment of usability with our skilled product designers and
                builders.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">03</div>
              <div class="overlays text-left">
                <img src={Prototype} alt="Prototype" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Prototype
              </h5>
              <p className="my-2 align-items-center description text-left">
                We make sure every product should be clickable to understand
                user experience. Find a knowledgeable development team with a
                CTO, who will provide critical performance technology and user
                structure.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">04</div>
              <div class="overlays text-left">
                <img src={SoftwareDevelopment} alt="SoftwareDevelopment" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Software Development
              </h5>
              <p className="my-2 align-items-center description text-left">
                Our engineers use advanced tools and development frameworks to
                ensure the construction of quality code in the shortest possible
                time.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">05</div>
              <div class="overlays text-left">
                <img src={Qa} alt="Qa" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Quality Assurance
              </h5>
              <p className="my-2 align-items-center description text-left">
                By combining both automated and manual testing of our solutions,
                We identify any issues or errors and ensure all users have an
                excellent experience.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">06</div>
              <div class="overlays text-left">
                <img src={Deployment} alt="Deployment" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Deployment
              </h5>
              <p className="my-2 align-items-center description text-left">
                Deployment of various technologies across many sites requires
                consistency and collaboration. We provide services that provide
                integration of all your releases from the initial survey to
                end-user support.
              </p>
            </div>
            <div className="col-lg-3 col-md-4 bg-light-v card-d text-center why-us-card">
              <div className="number ">07</div>
              <div class="overlays text-left">
                <img src={SupportMaintenance} alt="SupportMaintenance" />
              </div>
              <h5 className="font-weight-bold text-left sub-black-heading my-2">
                Support & Maintenance
              </h5>
              <p className="my-2 align-items-center description text-left">
                Our team has a unique ability to streamline the use and
                maintenance of large, complex, and highly visible software
                systems and mobile applications.
              </p>
            </div>
          </div>

          {/* <div className="col-10 mx-auto mb-xl-5 mb-llg-9 font-clr-blue">
          Stop wasting time and money on technology.
          <a href="#" target="_blank" className="h-n-link  font-weight-bold">
            Explore our company
          </a>
        </div> */}
          <div id="qoute" className="mb-xl-5 pb-xxl-5 pt--75 col-10"></div>
          <div className="mb-xl-5 pb-xl-5 pt--80 col-10 "></div>
        </div>
      </div>
    </div>
  );
}

export default Process;
