import React from "react";
import laptop from "../../../assets/expertise/noun_Laptop_18867.png";
import device from "../../../assets/expertise/noun_devices_931974.png";
import cloud from "../../../assets/expertise/noun_Cloud_1820804.png";
import blockchain from "../../../assets/expertise/noun_Blockchain_4321860.png";
import tech from "../../../assets/expertise/noun_smart consulting_3848580.svg";
import qa from "../../../assets/expertise/noun_quality assurance_1711288.svg";
import brand from "../../../assets/expertise/noun_Tablet_2545159.svg";
import market from "../../../assets/expertise/noun_digital marketing_3240470.png";
import { Link } from "react-router-dom";
function Coreservice(props) {
  return (
    <div className="bg-light-v py--80">
      <div className="row mx-auto">
        <div className="col-lg-12">
          <div className="col-lg-8 col-md-10 col-12 mx-auto text-center">
            <div className="col-12 upper font-clr-green p3">
              A ONE-STOP-SHOP DEVELOPMENT PARTNER
            </div>
            <h1 className="p9 darker font-weight-bold">
              Our Bespoke Software Development Services
            </h1>
            <p className="pb-xl-5 py-3 pb-4 para-d font-weight-lighter">
              We understand that successful solutions are only possible through
              collaboration. We listen to our client’s ideas and needs, then
              build a solution framework based on their feedback and our team’s
              extensive knowledge of all things design and programming.
            </p>
          </div>
          <div className="">
            <div className="row mx-auto services__items ex-cards bg-white over-flow-auto">
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={laptop}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        WEB <br></br> DEVELOPMENT
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">WEB Development</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <Link
                        to="/servicewebdevlopment"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={device}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        MOBILE <br></br>APP DEVELOPMENT
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">Mobile APP Development</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={blockchain}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        BLOCKCHAIN <br></br>DEVELOPMENT
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">BLOCKCHAIN DEVELOPMENT</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={cloud}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        CLOUD SERVICES <br></br> & DEVOPS
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">CLOUD SERVICES & DEVOPS</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={tech}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        TECHNOLOGY <br></br> CONSULTING
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">TECHNOLOGY CONSULTING</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={qa}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        QUALITY <br></br> ASSURANCE
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">QUALITY ASSURANCE</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={market}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        DIGITAL <br></br> MARKETING
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">DIGITAL MARKETING</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12 border-top px-0">
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white br-br-20 card-d-btn ex-card upcard mx-auto w-100 text-center exp-card-height border-r-center display-card">
                    <div className="pt-xl-3">
                      <img
                        src={brand}
                        className="item__description-logo"
                        alt="laptop"
                      />
                    </div>
                    <div className="p-xl-3">
                      <h3 class="font-weight-bold mb-0">
                        BRANDING <br></br>
                        MARKETING
                      </h3>
                    </div>
                    <div className="text-left parrot p6 font-weight-bold py-4 px-2 mb-1 afterup ex-card-des exp-card-height">
                      <h3 className="">Branding</h3>
                      <p className="font-weight-light font-16 text-white lh-2">
                        Meet your demanding deadlines with our mobile app
                        development services that converge a mobile platform,
                        custom development, and the latest tech.
                      </p>
                      <a
                        href="javascript:void(0)"
                        className="font-16 parrot font-Helvetica"
                      >
                        View More
                        <i className="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coreservice;
