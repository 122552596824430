import React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { Link, NavLink } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FloatingLabel from "react-bootstrap-floating-label";
function Footer(props) {
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="bg-blue py-5 footerTop">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 px-0">
            <div className="col-lg-12 col-12 mx-auto map-bg">
              <div className="text-white row mx-auto">
                <div className="col-12 mx-auto green-heading text-light mb-0">
                  LET’S GET STARTED
                </div>
                <h1 className="black-heading mx-auto mb-0 text-white">
                  Are you ready for a better, more productive business?
                </h1>
              </div>
              <p className="description text-light text-white mx-auto">
                Stop worrying about technology problems. Focus on your business.
                Let us provide the support you deserve.
              </p>
            </div>
            <a
              href="/home#qoute"
              className=" align-items-center green-hov-child svg"
            >
              <button className="border-0 footer-btn py-2 px-3 px-xl-5 mt-4">
                <span className="text-white d-block px-xl-2 py-1">
                  Get in Touch
                </span>
              </button>
            </a>
            <div className="row mx-auto mt-4 mt-xl-5 pt-xl-5 text-left">
              <div className="col-lg col-md col-6">
                <h5 className="font-weight-bold text-white mb-2 pb-2 upper p25">
                  EXPERTISE
                </h5>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Web Development
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Mobile App Dev
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Technology Consultant
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Digital Marketing
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Branding
                </a>
              </div>
              <div className="col-lg col-md col-6">
                <h5 className="font-weight-bold text-white mb-2 pb-2 upper p25">
                  Company
                </h5>
                <Link
                  to="/about"
                  className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov"
                >
                  About Us
                </Link>
                <a
                  href="/about#blogs"
                  className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov"
                >
                  Careers
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Custom Software
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Case Studies
                </a>
              </div>
              <div className="col-lg col-md col-6">
                <h5 className="font-weight-bold text-white mb-2 pb-2 upper p25">
                  Support
                </h5>
                <a
                  href="/about#blogs"
                  className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov"
                >
                  Blog
                </a>
                <a
                  href="/home#qoute"
                  className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov"
                >
                  Contact Us
                </a>
              </div>
              <div className="col-lg col-md col-6">
                <h5 className="font-weight-bold text-white mb-2 pb-2 upper p25">
                  Follow Us
                </h5>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Facebook
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Behance
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Linkedin
                </a>
                <a className="p8 p8-14 p8-14-under lighter low-op mb-2 pb-2 d-block pointer lighter-hov">
                  Youtube
                </a>
              </div>
              <div className="col-lg-4">
                <h5 className="font-weight-bold text-white mb-2 pb-2 upper p25">
                  Join Us
                </h5>
                <p className="p8 p8-14 lighter low-op mb-2 pb-2 lh-33 d-block">
                  By providing your e-mail address, you are consenting to
                  receive press releases, presentations and other information
                  concerning Digimark Developers. You may withdraw your consent
                  at.
                </p>
                <FormControl className="w-100" variant="secondary">
                  {/* <InputLabel htmlFor="standard-adornment-password">
                  Enter your Email
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={"text"}
                  value={values.password}
                  onChange={handleChange("password")}
                  className="w-100"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={handleMouseDownPassword}
                      >
                        <small className="font-clr-green">Subscribe</small>
                      </IconButton>
                    </InputAdornment>
                  }
                /> */}

                  <FloatingLabel
                    label="Enter Your Email"
                    className="mt-2 footer-float-label text-white"
                    onChange={(event) => console.log(event.target.value)}
                  />
                  <button className="subscribe btn-footer border-0 bg-trans text-parrot">
                    SUBSCRIBE
                  </button>
                </FormControl>
              </div>
              <div className="col-12 mx-auto p8 p8-14 pt-4 lighter low-op">
                Address: 1270 Beverley Rd Brooklyn, New York 11226 United States
              </div>
              <div className="col-12 mx-auto p8 p8-14 low-op pt-1 lighter">
                Phone:
                <a
                  href="tel:(0712) 819 79 555"
                  className="p8 p8-14 lighter lighter-hov"
                >
                  {" "}
                  (0712) 819 79 555
                </a>{" "}
                Mail:
                <a
                  href="mailto:contact@example.com"
                  className="p8 p8-14 lighter lighter-hov"
                >
                  {" "}
                  contact@example.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
