import React, { useState } from "react";
import "../../styles/cards.css";
import ServicesCarousel from "./ServicesCarousel";
const Card = () => {
  const [step, setStep] = useState(false);
  const changepage = () => {
    setStep(!step);
  };
  return (
    <div className="col-lg-12 px-0">
      <div className="justify-content-center pt--80">
        <div className="container">
          <h5 className="green-heading">OUR EXPERTISE</h5>
          <div className="row mx-auto">
            <h1 className="col-lg-12 col-12 col-md-12 black-heading mx-auto mb-4">
              Stay Up, Stay Running, Stay Protected
            </h1>
          </div>
          {/* <div class='expertise-btn mx-auto row mt-3'>
            <button
              className={`${
                step ? 'industies-btn' : `services-btn`
              }  py-3 px-lg-4 px-3 serv-dim`}
              onClick={changepage}
            >
              <span
                class={`py-lg-3 py-3 px-lg-4 px-3 p13 ${
                  !step ? 'font-weight-bold' : ''
                }`}
              >
                Expertise
              </span>
            </button>
            <button
              className={` ${
                step ? 'services-btn' : `industies-btn`
              }  py-2 px-lg-4 px-3 serv-dim`}
              onClick={changepage}
            >
              <span
                class={`py-lg-3 py-2 px-lg-4 px-3 p13 ${
                  step ? 'font-weight-bold' : ''
                }`}
              >
                Industries
              </span>
            </button>
            <div class='jss2 jss3'></div>
          </div> */}
          {!step ? (
            <>
              <ServicesCarousel />
            </>
          ) : (
            ""
          )}
          {/* <div className="col-10 mx-auto my-3 font-clr-blue">
          IT services built specifically for your business.
          <a href="#" target="_blank" className="h-n-link  font-weight-bold">
            Find your solution
          </a>
        </div> */}
          <div className="col-12 mx-auto short-heading">
            <p className="link-heading">
              {" "}
              We build digital solutions specifically for your business.
              <a
                href="#"
                target="_blank"
                className="h-n-link darker p8 font-weight-bold"
              >
                Find your services
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
