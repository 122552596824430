import React from "react";

function Services(props) {
  return (
    <div className="bg-light-v py--80">
      <div className="row mx-auto">
        <div className="col-lg-12">
          <div className="row mx-auto text-left">
            <div className="col-lg-7 col-md-6 mb-4">
              <div className="black-heading small-black-heading text-left">
                YOUR INNOVATIVE SOLUTION PARTNER IN DIGITAL WORLD
              </div>
              <div className="row mx-auto">
                <div className="col-xl col-6 innovation col-md  pl-0">
                  <div className="black-heading small-black-heading mb-1">
                    30<span className="conter-light">+</span>
                  </div>
                  <div className="description para-d">
                    Experts across a range of specializations
                  </div>
                </div>
                <div className="col-xl col-6 innovation col-md  pl-0">
                  <div className="black-heading small-black-heading mb-1">
                    03
                  </div>
                  <div className="description para-d">
                    So far we have offices across 04 countries
                  </div>
                </div>
                <div className="col-xl col-6 innovation col-md pl-0">
                  <div className="black-heading small-black-heading mb-1">
                    04Y
                  </div>
                  <div className="description para-d">
                    We have been active in business
                  </div>
                </div>
              </div>
              <div className="row mx-auto">
                <div className="col-lg-7 col-xl-5 col-md-10 col-12 px-0 mr-auto">
                  <button class="border-0 profile-dwn-btn zoom-in btn-block bg-blue ">
                    <span class="text-white d-block text-cengter w-100 py-1 upper">
                      <i class="fas fa-cloud-download-alt fa-lg"></i> &nbsp;
                      Download Our profile
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 mb-4">
              <div className="description para-d text-left">
                Headquartered in Orlando, Florida, DigiMark Developers is an
                international agency providing website and mobile digital
                solutions for businesses worldwide.
              </div>
              <div className="description para-d text-left mt-2">
                DigiMark Developers has worked with organizations of all sizes
                to create solutions to meet their business needs. Our agency is
                comprised of flexible teams that can be scaled up or down to
                meet the demands of any business for any project at any time.
                Collaborating with our clients is the first step towards
                designing a quality solution.
              </div>
            </div>
            <div className="">
              <div className="row mx-auto justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="bg-white innovation-card card-d card-d-dark service-card-h text-center mt-3">
                    <h4 className="sub-black-heading">What We Do</h4>
                    <div class="mb-2 py-3 overlay-box1 mb-0 row mx-auto text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="97.4"
                        height="64"
                        className="mx-auto"
                        viewBox="0 0 97.4 71"
                      >
                        <g
                          id="noun_service_878097"
                          transform="translate(-1.3 -14.5)"
                        >
                          <path
                            id="Path_106"
                            data-name="Path 106"
                            d="M87.5,46.8,79.7,49h0a3.064,3.064,0,0,1-.3,2.3,2.852,2.852,0,0,1-1.9,1.5,3.658,3.658,0,0,1-2.5-.3L70.5,50l.4-.8,4.5,2.5a2.292,2.292,0,0,0,1.8.2,2.586,2.586,0,0,0,1.4-1.1,1.613,1.613,0,0,0,.3-.9,1.961,1.961,0,0,0-.9-2L67.1,42a3.175,3.175,0,0,0-2.7-.3h0c-.3.1-12,3.4-12.5,3.5h0l2.4,8.4,7.8-2.2c.8.5,6.8,4.2,7.5,4.6a4.442,4.442,0,0,0,3.3.3l16-4.5a2.731,2.731,0,0,0,1.6-3.3A2.5,2.5,0,0,0,87.5,46.8Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_107"
                            data-name="Path 107"
                            d="M88.2,33.9H86.8a6.715,6.715,0,0,0-1-2.3L87,30.4a.5.5,0,0,0,0-.6l-1.6-1.6c-.2-.2-.5-.2-.6,0l-1.2,1.2a7.837,7.837,0,0,0-2.1-.9V27a.43.43,0,0,0-.4-.4H78.9a.43.43,0,0,0-.4.4v1.5a8.488,8.488,0,0,0-2.2.9L75,28.1c-.2-.2-.5-.2-.6,0l-1.6,1.6a.5.5,0,0,0,0,.6L74,31.5a6.432,6.432,0,0,0-1,2.4H71.6a.43.43,0,0,0-.4.4v2.2a.43.43,0,0,0,.4.4H73a8.488,8.488,0,0,0,.9,2.2l-1.1,1.1a.5.5,0,0,0,0,.6l1.6,1.6a.5.5,0,0,0,.6,0l1.1-1.1a6.432,6.432,0,0,0,2.4,1v1.3a.43.43,0,0,0,.4.4H81a.43.43,0,0,0,.4-.4V42.3a6.715,6.715,0,0,0,2.3-1l.9.9a.5.5,0,0,0,.6,0l1.6-1.6a.5.5,0,0,0,0-.6l-1-1a8.488,8.488,0,0,0,.9-2.2h1.4a.43.43,0,0,0,.4-.4V34.2C88.6,34.1,88.4,33.9,88.2,33.9Zm-8.3,5.9a4.4,4.4,0,1,1,4.4-4.4A4.439,4.439,0,0,1,79.9,39.8Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_108"
                            data-name="Path 108"
                            d="M96.4,14.5H3.6a2.263,2.263,0,0,0-2.3,2.3V75.9a2.263,2.263,0,0,0,2.3,2.3H8.7V73.6H5.9V19H94.2V73.6H61v4.6H96.4a2.263,2.263,0,0,0,2.3-2.3V16.7A2.242,2.242,0,0,0,96.4,14.5Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_109"
                            data-name="Path 109"
                            d="M33.4,73.5,32,79.4l4.6-3.3L36,73.5l1.9-2.2a.188.188,0,0,0,0-.3l-1.2-2.9c0-.1-.1-.1-.2-.1H32.8a.349.349,0,0,0-.2.1L31.5,71v.3Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_110"
                            data-name="Path 110"
                            d="M34.7,36.6A13.3,13.3,0,1,0,48,49.9,13.294,13.294,0,0,0,34.7,36.6Zm0,23.7A10.285,10.285,0,0,1,24.4,50a10.075,10.075,0,0,1,.3-2.5A13.617,13.617,0,0,0,30.6,49a8.879,8.879,0,0,0,5.6-1.8c.2-.1,4.7-3.4,8.4-.1a9.476,9.476,0,0,1,.4,3A10.264,10.264,0,0,1,34.7,60.3Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_111"
                            data-name="Path 111"
                            d="M38.9,85.5l-.7-2.8-4,2.8Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_112"
                            data-name="Path 112"
                            d="M31.4,85.5,37.8,81l-.7-3.2-5.7,4-.9,3.7Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_113"
                            data-name="Path 113"
                            d="M58.7,85.5v-6A11.608,11.608,0,0,0,47.1,67.9h-.4L41.4,85.5Z"
                            fill="#1D4354"
                          />
                          <path
                            id="Path_114"
                            data-name="Path 114"
                            d="M28,85.5,22.9,67.9h-.5A11.608,11.608,0,0,0,10.8,79.5v6Z"
                            fill="#1D4354"
                          />
                        </g>
                      </svg>
                    </div>
                    <p className="mb-5 align-items-center lh-2 mx-1 px-lg-0 description para-d">
                      Achieve your business goals and create digital solutions
                      that people love with our development approach
                    </p>
                    <button className="card-d-btn p-0 card-link-heading">
                      <div className="row align-items-center">
                        <p className="m-0 font-weight-bold">
                          View Our Expertise
                        </p>
                        <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="bg-white innovation-card card-d card-d-dark service-card-h text-center mt-3">
                    <h4 className="sub-black-heading">Who We Help?</h4>
                    <div class="mb-2 py-3 overlay-box1 mb-0 row mx-auto text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81.968"
                        height="64"
                        viewBox="0 0 81.968 95.104"
                        className="mx-auto"
                      >
                        <g
                          id="noun_help_3407902"
                          transform="translate(-9.032 -2.5)"
                        >
                          <g id="Group_184" data-name="Group 184">
                            <path
                              id="Path_115"
                              data-name="Path 115"
                              d="M69.3,2.5c-12,0-21.7,8.6-21.7,19.1a18.029,18.029,0,0,0,6.3,13.5A21.775,21.775,0,0,1,49.2,40a1.382,1.382,0,0,0,.8,2.5c4.1.1,8.7-1.3,12.4-2.8a26.143,26.143,0,0,0,6.9,1c12,0,21.7-8.6,21.7-19.1S81.3,2.5,69.3,2.5Zm-.2,31.8a2.8,2.8,0,1,1,2.8-2.8A2.86,2.86,0,0,1,69.1,34.3Zm3.4-11.1c-1.3,1.1-1.3,1.6-1.3,1.8v1a.9.9,0,0,1-.9.9H67.8a.9.9,0,0,1-.9-.9V25c0-1.9,1-3.6,2.9-5.2,1.4-1.1,2.9-2.5,2.9-3.7,0-1.9-.9-2.7-3.2-2.7C66,13.4,66,15.9,66,16v.5a.9.9,0,0,1-.9.9H62.6a.9.9,0,0,1-.9-.9V16c0-2.8,2.1-7,7.9-7C75,9,77,12.6,77,16.1S74.2,21.9,72.5,23.2Z"
                              fill="#1d4354"
                            />
                            <circle
                              id="Ellipse_6"
                              data-name="Ellipse 6"
                              cx="8.7"
                              cy="8.7"
                              r="8.7"
                              transform="translate(23.8 21)"
                              fill="#1d4354"
                            />
                            <path
                              id="Path_116"
                              data-name="Path 116"
                              d="M45,45.4a10.024,10.024,0,0,0-8.6-4.8H29.5A14.52,14.52,0,0,1,23,39a9.968,9.968,0,0,1-5-6.8L16.8,25a3.922,3.922,0,1,0-7.7,1.5l1.3,7.1a17.633,17.633,0,0,0,8.9,12.3,20.76,20.76,0,0,0,4.9,1.9L22.1,93.2a4.158,4.158,0,1,0,8.3.5L31.8,68h1.4l1.4,25.6a4.171,4.171,0,0,0,4.2,3.9H39a4.085,4.085,0,0,0,3.9-4.4L41.1,54,47,63.8a3.859,3.859,0,0,0,3.3,1.9,3.606,3.606,0,0,0,2-.6,3.983,3.983,0,0,0,1.3-5.4Z"
                              fill="#1d4354"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p className="mb-5 align-items-center lh-2 mx-1 px-lg-0 description para-d">
                      We believe all businesses deserves the opportunity to
                      grow, and establish a stronger impact in their market.
                    </p>
                    <button className="card-d-btn p-0 card-link-heading">
                      <div className="row align-items-center">
                        <p className="m-0 font-weight-bold">
                          Industries We Serve
                        </p>
                        <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="bg-white innovation-card card-d card-d-dark service-card-h text-center mt-3">
                    <h4 className="sub-black-heading">Why Choose Us</h4>
                    <div class="mb-2 py-3 overlay-box1 mb-0 row mx-auto text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="84"
                        height="64"
                        viewBox="0 0 84 84"
                        className="mx-auto"
                      >
                        <g
                          id="noun_choose_2177201"
                          transform="translate(-1 -2)"
                        >
                          <g
                            id="Group_185"
                            data-name="Group 185"
                            transform="translate(47.61 51.41)"
                          >
                            <rect
                              id="Rectangle_78"
                              data-name="Rectangle 78"
                              width="4.2"
                              height="2.8"
                              transform="translate(0 2.97) rotate(-45)"
                              fill="#1d4354"
                            />
                          </g>
                          <g
                            id="Group_186"
                            data-name="Group 186"
                            transform="translate(30.81 51.41)"
                          >
                            <rect
                              id="Rectangle_79"
                              data-name="Rectangle 79"
                              width="2.8"
                              height="4.2"
                              transform="translate(0 1.98) rotate(-45)"
                              fill="#1d4354"
                            />
                          </g>
                          <g
                            id="Group_187"
                            data-name="Group 187"
                            transform="translate(50.275 59.526)"
                          >
                            <rect
                              id="Rectangle_80"
                              data-name="Rectangle 80"
                              width="2.799"
                              height="4.2"
                              transform="matrix(0.322, -0.947, 0.947, 0.322, 0, 2.649)"
                              fill="#1d4354"
                            />
                          </g>
                          <g
                            id="Group_188"
                            data-name="Group 188"
                            transform="translate(27.901 59.132)"
                          >
                            <rect
                              id="Rectangle_81"
                              data-name="Rectangle 81"
                              width="4.199"
                              height="2.8"
                              transform="translate(0 1.808) rotate(-25.512)"
                              fill="#1d4354"
                            />
                          </g>
                          <path
                            id="Path_117"
                            data-name="Path 117"
                            d="M1,57.532V69.6H17.8V57.532A4.732,4.732,0,0,0,13.068,52.8a5.6,5.6,0,1,0-7.336,0A4.732,4.732,0,0,0,1,57.532Zm5.6.868,2.8-4.2,2.8,4.2L9.4,64Z"
                            transform="translate(0 16.4)"
                            fill="#1d4354"
                          />
                          <path
                            id="Path_118"
                            data-name="Path 118"
                            d="M24,57.532V69.6H40.8V57.532A4.732,4.732,0,0,0,36.068,52.8a5.6,5.6,0,1,0-7.337,0A4.729,4.729,0,0,0,24,57.532Zm5.6.868,2.8-4.2,2.8,4.2L32.4,64Z"
                            transform="translate(9.2 16.4)"
                            fill="#1d4354"
                          />
                          <path
                            id="Path_119"
                            data-name="Path 119"
                            d="M49,57.532V69.6H65.8V57.532A4.732,4.732,0,0,0,61.068,52.8a5.6,5.6,0,1,0-7.337,0A4.729,4.729,0,0,0,49,57.532Zm5.6.868,2.8-4.2,2.8,4.2L57.4,64Z"
                            transform="translate(19.2 16.4)"
                            fill="#1d4354"
                          />
                          <path
                            id="Path_120"
                            data-name="Path 120"
                            d="M48.8,7.6V2H29.2V7.6L25,10.4V21.373a6.128,6.128,0,0,0,2.8,5.132V21.6h1.4V45.4a2.8,2.8,0,1,0,5.6,0V28.6h1.4V37a4.2,4.2,0,0,0,4.2-4.2v-7h1.4v7A4.2,4.2,0,0,0,46,28.6V23h1.4v5.6a4.2,4.2,0,0,0,4.2-4.2V11.8Z"
                            transform="translate(9.6)"
                            fill="#1d4354"
                          />
                        </g>
                      </svg>
                    </div>
                    <p className="mb-5 align-items-center lh-2 mx-1 px-lg-0 description para-d">
                      We have built a great team of engineers that we use to
                      deliver solutions that meet customer needs.
                    </p>
                    <button className="card-d-btn p-0 card-link-heading">
                      <div className="row align-items-center">
                        <p className="m-0 font-weight-bold">Find Out More</p>
                        <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
