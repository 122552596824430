import React from "react";

function Technologies(props) {
  return (
    <div className="col-lg-12">
      <div className="row mx-auto bg-white">
        <div className="col-xl-9 mx-auto">
          <div className="row mx-auto text-center pt--80">
            <div className="col-12 upper">
              <h5 className="green-heading">TECHNOLOGIES</h5>
            </div>
            <div className="col-12 col-md-5 col-lg-8 mx-auto black-heading my-2">
              LANGUAGES, TOOLS & FRAMEWORKS
            </div>
            <div className="col-12 para-d description mb-4">
              Whatever the field or platform, we make it happen.
            </div>
          </div>
        </div>
      </div>
      <div className="row text-left">
        <div className="col-lg-12 mt-2 pb--80">
          <div className="row m-auto justify-content-center">
            <div className="col-xl col-md col-6 mt-4">
              <div className="tech-heading upper border-bottom py-xl-3">
                design
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Photoshop
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Illustrator
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Adobe XD
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                After Effect
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Figma
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Sketch
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Invision
              </div>
              <div className="tech-description mt-1 py-xl-3">Zepline</div>
            </div>
            <div className="col-xl col-md col-6 mt-4">
              <div className="tech-heading upper border-bottom py-xl-3">
                MOBILE
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Kotlin
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Swift
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Java
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                React Native
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Flutter
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Ionic
              </div>
              <div className="tech-description mt-1 py-xl-3">Objective C</div>
            </div>
            <div className="col-xl col-md col-6 mt-4">
              <div className="tech-heading upper border-bottom py-xl-3">
                TECHNOLOGIES
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                PHP
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Ruby on Rails
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Phyton
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Visual Basic
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                SQL
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                JavaScript
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                XML
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                JAVA
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Swift
              </div>
              <div className="tech-description mt-1 py-xl-3">C#</div>
            </div>
            <div className="col-xl col-md col-6 mt-4">
              <div className="tech-heading upper border-bottom py-xl-3">
                FRAMEWORKS
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Angular JS
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                React JS
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Vue JS
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Next JS
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Node Js
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                .Net
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Laravel
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Djanjo
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Drupal
              </div>
              <div className="tech-description mt-1 py-xl-3">WordPress</div>
            </div>
            <div className="col-xl col-md col-6 mt-4">
              <div className="tech-heading upper border-bottom py-xl-3">
                SERVER SIDE
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                LAMP
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Node.js
              </div>
              <div className="tech-description border-bottom mt-1 py-xl-3">
                Mongo
              </div>
              <div className="tech-description mt-1 py-xl-3">Redls</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technologies;
