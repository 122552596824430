import React from "react";

function Techtext(props) {
  return (
    <div className="col-12 mx-auto short-heading">
      <p className="link-heading">
        Stop wasting time and money on technology.
        <a
          href="#"
          target="_blank"
          className="h-n-link darker font-weight-bold"
        >
          {props.text}
        </a>
      </p>
    </div>
  );
}

export default Techtext;
