//Navbar.js
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Dropdown,
  Button,
} from "react-bootstrap";

const title = "Business Developer";

function MenuBar({ ChangeStep, step }) {
  const changesStep = (t)=>{
    ChangeStep(t)
    window.scrollTo(0,400);
  }
  const [isTop, setIsTop] = useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 360) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }
  return (
    <Navbar
      expand="xl"
      className={`sticky-menu ${scrolled ? "bg-menu-header" : ""}`}
      sticky="top"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        className="row mx-auto resp-nav"
        id="basic-navbar-nav"
      >
        <Nav className="col-xl-10 m-auto d-xl-block navbar-nav px-0 navbar-nav text-left">
          <div className="row">
            <button
              onClick={() => changesStep(1)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
              ${
                step == 1
                  ? "nav-menu-active font-clr-blu font-weight-bold "
                  : "nav-menu-link darker "
              }
                ${scrolled?"text-white":""}
              `}
            >
              Who We Are
            </button>
            <button
              onClick={() => changesStep(2)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
              ${
                step == 2
                  ? "nav-menu-active font-clr-blu font-weight-bold"
                  : "nav-menu-link darker"
              } ${scrolled?"text-white":""}
              `}
            >
              Why Choose Us
            </button>
            <button
              onClick={() => changesStep(3)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
              ${
                step == 3
                  ? "nav-menu-active font-clr-blu font-weight-bold"
                  : "nav-menu-link darker"
              }
              ${scrolled?" text-white":""}
              `}
            >
              Our Values
            </button>
            {/* <button
              onClick={() => changesStep(4)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
              ${
                step == 4
                  ? "nav-menu-active font-clr-blu font-weight-bold"
                  : "nav-menu-link darker"
              }
              ${scrolled?" text-white":""}
              `}
            >
              Our History
            </button> */}
            <button
              onClick={() => changesStep(5)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
             ${
               step == 5
                 ? "nav-menu-active font-clr-blu font-weight-bold"
                 : "nav-menu-link darker"
             }
             ${scrolled?" text-white":""}
             `}
            >
              Technologies
            </button>
            <button
              onClick={() => changesStep(6)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
            ${
              step == 6
                ? "nav-menu-active font-clr-blu font-weight-bold"
                : "nav-menu-link darker"
            }
            ${scrolled?" text-white":""}
            `}
            >
              Careers
            </button>
            <button
              onClick={() => changesStep(7)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
          ${
            step == 7
              ? "nav-menu-active font-clr-blu font-weight-bold"
              : "nav-menu-link darker"
          }
          ${scrolled?" text-white":""}
          `}
            >
              Meet Our Team
            </button>
            <button
              onClick={() => changesStep(8)}
              className={`green-hov border-0 bg-trans font-16 mx-xxl-1 py-xl-2 col text-center font-weight-lighter
            ${
              step == 8
                ? "nav-menu-active font-clr-blu font-weight-bold"
                : "nav-menu-link darker"
            }
            ${scrolled?" text-white":""}
            `}
            >
              FAQ
            </button>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default MenuBar;
