import React, { useState } from "react";
import ourservice from "../../../assets/ourservice.png";
import whypartner from "../../../assets/whypartner.jpg";
import CustomerBack from "../../../assets/customer-back.png";
import ExpertiseBack from "../../../assets/expertise-back.png";
import casestudy from "../../../assets/casestudy.png";
function Ourcompany(props) {
  const [service, setService] = useState(false);
  const [partner, setPartner] = useState(false);
  //   const [casestudy, setCasestudy] = useState(false);
  return (
    <div className="bg-seconday">
      <div className="container">
        <div className="row sans text-white">
          <div className="col-lg-12 mx-auto section-top-space">
            <div className="row">
              <div className="col-lg-6 col-md-12 text-left ">
                <h5 className="green-heading">Our Company</h5>
                <p className="mb-3 white-heading">
                  {" "}
                  Robust Web & Mobile digital Solutions for Your Business
                </p>
              </div>
              <div className="col-lg-6 col-md-12 text-left">
                <p className="mb-0 description">
                  Today’s modern business environment requires organizations to
                  provide their customers with robust and intelligent web and
                  mobile solutions. We understand that successful solutions are
                  only possible through collaboration. We listen to our client’s
                  ideas and needs, then build a solution framework based on
                  their feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light-vv text-light">
        <div className="container-fluid">
          <div className="col-12 col-xl-12 mx-auto section-top-space company-card px-0">
            <div className="row mx-auto">
              <div className="col-xl col-md mx-auto mb-4 mb-md-0 px-sm-3 px-0">
                <img
                  src={ExpertiseBack}
                  alt=""
                  className="w-100 card-h-resp br-10"
                />
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title">
                    <h5 className=" font-clr-green mb-1 font-weight-bold op">
                      Our Services
                    </h5>
                    <h4 className="mb-1 mt-0 secondary upcardwhite ">
                      How we can help
                    </h4>
                    <div className="parrot  mb-1 afterup">
                      Learn More
                      <i className="fa fa-arrow-right ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl col-md mx-auto mb-4 mb-md-0 px-sm-3 px-0">
                <img
                  src={whypartner}
                  alt=""
                  className="w-100 card-h-resp br-10"
                />
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title">
                    <h5 className=" font-clr-green mb-1 op font-weight-bold">
                      Our expertise
                    </h5>
                    <h4 className="mb-1 mt-0 secondary upcardwhite ">
                      Why partner with us
                    </h4>
                    <div className="parrot  font-weight-bold mb-1 afterup">
                      Learn More
                      <i className="fa fa-arrow-right ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl col-md mx-auto px-sm-3 px-0">
                <img
                  src={CustomerBack}
                  alt=""
                  className="w-100 card-h-resp br-10"
                />
                <div className="row mx-auto justify-content-center">
                  <div className="bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title">
                    <h5 className=" font-clr-green mb-1 op font-weight-bold">
                      Case Studies
                    </h5>
                    <h4 className="mb-1 mt-0 secondary upcardwhite ">
                      Case studies stories
                    </h4>
                    <div className="parrot  mb-1 afterup font-weight-bold">
                      Learn More
                      <i className="fa fa-arrow-right ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mx-auto short-heading">
          <p className="link-heading">
            Stop wasting time and money on technology.
            <a
              href="#"
              target="_blank"
              className="h-n-link darker font-weight-bold"
            >
              Explore our company
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Ourcompany;
