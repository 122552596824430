import React from "react";
import img from "../../assets/group 47.jpg";
import { Link } from "react-router-dom";
function industryban(props) {
  return (
    <div>
      <div class="casestudy-banner my-0">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6 col-md-7">
              <div className="text-white text-left">
                <div className="p3 font-weight-light mb-3 mb-xl-5">
                  Home
                  <span className="font-weight-bold"> / CaseStudies</span>
                </div>
                <div className="p8 font-weight-light">CASE STUDIES</div>
                <div class="p9 font-weight-bold mx-auto">
                  SUCCESS STORIES - SEE HOW WE’VE HELPED OUR CLIENTS{" "}
                  <span className="bb-lgreen ml-2"> OUR CLIENTS</span>
                </div>
                <div class="font-weight-light mt-3 mt-5 p3 helvetica mx-auto">
                  <p>
                    Here are some examples of the excellent products that we’ve
                    helped to send out into the world.
                  </p>
                </div>
                <div class="font-weight-light font-14 mt-4 helvetica mx-auto">
                  <p>
                    WORK WITH US <i className="fa fa-arrow-right ml-2"></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5"></div>
          </div>
        </div>
      </div>
      <div className="row mx-auto py--80">
        <div className="col-lg-12">
          <div className="row mx-auto">
            <div className="p6 darker col-12 font-weight-bold mb-2 text-left ">
              Filter Case Studies...
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <Link
                        to="/semicondcutor"
                        className="button text-center button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter"
                      >
                        <span className="d-block p16">VIEW DETAIL</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter">
                        <span className="d-block p16">VIEW DETAIL</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter">
                        <span className="d-block p16">VIEW DETAIL</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter">
                        <span className="d-block p16">VIEW DETAIL</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter">
                        <span className="d-block p16">VIEW DETAIL</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 col-lg-4 col-md-6 col-12">
              <div class="card h-100">
                <div className="img-container px-xxl-5 pt-xxl-5">
                  <img
                    src={img}
                    // height="171px"
                    className="card-img-top border no-radius"
                    alt="Snow"
                  />
                  <div class="centered p6 font-weight-bold mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pt-0">
                  <div className="py-3 px-2 px-md-1 px-lg-2 px-xl-5">
                    <h6 className="font-clr-green text-left w-100 p3 font-weight-light">
                      Cyber Security
                    </h6>
                    <h5 className="font-clr-blue font-weight-bold mb-2 mt-0 lh-15 p20">
                      Support center for semiconductor provider
                    </h5>
                    <p class="card-text p15 para-d mb-3 pb-3 ">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan case-btn-h hov-btn-color py-2 px-3 br-15 helvetica font-weight-lighter">
                        <span className="d-block p16">VIEW DETAIL</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default industryban;
