import React from "react";

function Journey(props) {
  return (
    <div className="col-lg-12">
      <div classname="row mx-auto">
        <div className="row mx-auto py-4">
          <div className="col-xl col-12 col-md-4 px-0 mb-5">
            <div class="img-container bg-light my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100% "
                height="auto"
                viewBox="0 0 264 212"
                class="h-auto card-img-top no-radius about-banner"
              >
                <g
                  id="Group_3948"
                  data-name="Group 3948"
                  transform="translate(-277.5 -3609)"
                >
                  <rect
                    id="Rectangle_827"
                    data-name="Rectangle 827"
                    width="262"
                    height="212"
                    transform="translate(278 3609)"
                    fill="#fff"
                  />
                  <line
                    id="Line_25"
                    data-name="Line 25"
                    y2="206"
                    transform="translate(279.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                  <line
                    id="Line_26"
                    data-name="Line 26"
                    y2="206"
                    transform="translate(539.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                </g>
              </svg>
              <div className="about-centered about-centered1 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="60.2"
                  viewBox="0 0 44 60.2"
                >
                  <g
                    id="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                    transform="translate(-10 -1.9)"
                  >
                    <path
                      id="layer2"
                      d="M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z"
                      fill="#8d8ea3"
                    />
                    <path
                      id="layer1"
                      d="M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z"
                      fill="#253858"
                    />
                  </g>
                </svg>
                <div class="p20 font-clr-blu font-weight-bold mt-2 mx-auto">
                  Established
                </div>
                <div class="p4 font-clr-green font-weight-bold mx-auto my-3">
                  2016
                </div>
                <div class="p20 font-clr-blu font-weight-bold mx-auto">
                  About Us <i className="fa fa-arrow-right font-clr-green"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl col-12 col-md-4 px-0 mb-5">
            <div class="img-container my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100% "
                height="auto"
                viewBox="0 0 264 212"
                class="h-auto card-img-top no-radius about-banner"
              >
                <g
                  id="Group_3948"
                  data-name="Group 3948"
                  transform="translate(-277.5 -3609)"
                >
                  <rect
                    id="Rectangle_827"
                    data-name="Rectangle 827"
                    width="262"
                    height="212"
                    transform="translate(278 3609)"
                    fill="#fff"
                  />
                  <line
                    id="Line_25"
                    data-name="Line 25"
                    y2="206"
                    transform="translate(279.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                  <line
                    id="Line_26"
                    data-name="Line 26"
                    y2="206"
                    transform="translate(539.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                </g>
              </svg>
              <div className="about-centered about-centered1 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="60.2"
                  viewBox="0 0 44 60.2"
                >
                  <g
                    id="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                    transform="translate(-10 -1.9)"
                  >
                    <path
                      id="layer2"
                      d="M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z"
                      fill="#8d8ea3"
                    />
                    <path
                      id="layer1"
                      d="M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z"
                      fill="#253858"
                    />
                  </g>
                </svg>
                <div class="p20 font-clr-blu font-weight-bold mt-2 mx-auto">
                  Client Retention
                </div>
                <div class="p4 font-clr-green font-weight-bold mx-auto my-3">
                  2016
                </div>
                <div class="p20 font-clr-blu font-weight-bold mx-auto">
                  Reviews <i className="fa fa-arrow-right font-clr-green"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl col-12 col-md-4 px-0 mb-5">
            <div class="img-container my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100% "
                height="auto"
                viewBox="0 0 264 212"
                class="h-auto card-img-top no-radius about-banner"
              >
                <g
                  id="Group_3948"
                  data-name="Group 3948"
                  transform="translate(-277.5 -3609)"
                >
                  <rect
                    id="Rectangle_827"
                    data-name="Rectangle 827"
                    width="262"
                    height="212"
                    transform="translate(278 3609)"
                    fill="#fff"
                  />
                  <line
                    id="Line_25"
                    data-name="Line 25"
                    y2="206"
                    transform="translate(279.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                  <line
                    id="Line_26"
                    data-name="Line 26"
                    y2="206"
                    transform="translate(539.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                </g>
              </svg>
              <div className="about-centered about-centered1 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="60.2"
                  viewBox="0 0 44 60.2"
                >
                  <g
                    id="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                    transform="translate(-10 -1.9)"
                  >
                    <path
                      id="layer2"
                      d="M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z"
                      fill="#8d8ea3"
                    />
                    <path
                      id="layer1"
                      d="M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z"
                      fill="#253858"
                    />
                  </g>
                </svg>
                <div class="p20 font-clr-blu font-weight-bold mt-2 mx-auto">
                  Clients
                </div>
                <div class="p4 font-clr-green font-weight-bold mx-auto my-3">
                  2016
                </div>
                <div class="p20 font-clr-blu font-weight-bold mx-auto">
                  Results <i className="fa fa-arrow-right font-clr-green"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl col-12 col-md-4 mll-md-20 px-0 mb-5">
            <div class="img-container my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100% "
                height="auto"
                viewBox="0 0 264 212"
                class="h-auto card-img-top no-radius about-banner"
              >
                <g
                  id="Group_3948"
                  data-name="Group 3948"
                  transform="translate(-277.5 -3609)"
                >
                  <rect
                    id="Rectangle_827"
                    data-name="Rectangle 827"
                    width="262"
                    height="212"
                    transform="translate(278 3609)"
                    fill="#fff"
                  />
                  <line
                    id="Line_25"
                    data-name="Line 25"
                    y2="206"
                    transform="translate(279.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                  <line
                    id="Line_26"
                    data-name="Line 26"
                    y2="206"
                    transform="translate(539.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                </g>
              </svg>
              <div className="about-centered about-centered1 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="60.2"
                  viewBox="0 0 44 60.2"
                >
                  <g
                    id="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                    transform="translate(-10 -1.9)"
                  >
                    <path
                      id="layer2"
                      d="M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z"
                      fill="#8d8ea3"
                    />
                    <path
                      id="layer1"
                      d="M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z"
                      fill="#253858"
                    />
                  </g>
                </svg>
                <div class="p20 font-clr-blu font-weight-bold mt-2 mx-auto">
                  Established
                </div>
                <div class="p4 font-clr-green font-weight-bold mx-auto my-3">
                  2016
                </div>
                <div class="p20 font-clr-blu font-weight-bold mx-auto">
                  About Us <i className="fa fa-arrow-right font-clr-green"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl col-12 col-md-4 px-0 mb-5">
            <div class="img-container my-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100% "
                height="auto"
                viewBox="0 0 264 212"
                class="h-auto card-img-top no-radius about-banner"
              >
                <g
                  id="Group_3948"
                  data-name="Group 3948"
                  transform="translate(-277.5 -3609)"
                >
                  <rect
                    id="Rectangle_827"
                    data-name="Rectangle 827"
                    width="262"
                    height="212"
                    transform="translate(278 3609)"
                    fill="#fff"
                  />
                  <line
                    id="Line_25"
                    data-name="Line 25"
                    y2="206"
                    transform="translate(279.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                  <line
                    id="Line_26"
                    data-name="Line 26"
                    y2="206"
                    transform="translate(539.5 3612)"
                    fill="none"
                    stroke="#ccc"
                    stroke-width="4"
                    stroke-dasharray="5"
                  />
                </g>
              </svg>
              <div className="about-centered about-centered1 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="60.2"
                  viewBox="0 0 44 60.2"
                >
                  <g
                    id="diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0"
                    transform="translate(-10 -1.9)"
                  >
                    <path
                      id="layer2"
                      d="M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z"
                      fill="#8d8ea3"
                    />
                    <path
                      id="layer1"
                      d="M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z"
                      fill="#253858"
                    />
                  </g>
                </svg>
                <div class="p20 font-clr-blu font-weight-bold mt-2 mx-auto">
                  Global Offices
                </div>
                <div class="p4 font-clr-green font-weight-bold mx-auto my-3">
                  2016
                </div>
                <div class="p20 font-clr-blu font-weight-bold mx-auto">
                  Contact Us{" "}
                  <i className="fa fa-arrow-right font-clr-green"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Journey;
