import React from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Scrolltop from "../../shared/Scrolltop";
import StickyHeader from "../../shared/StickyHeader";
import pc_img from "../../../assets/expertise/webdevelopment-slide-img.png";
import img_1 from "../../../assets/expertise/Services Details Page (1)/Project_4383336.svg";
import img_2 from "../../../assets/expertise/Services Details Page (1)/Interest Percentage_4037495.svg";
import img_3 from "../../../assets/expertise/Services Details Page (1)/b2b.svg";
import img_4 from "../../../assets/expertise/Services Details Page (1)/Technology_3761631.svg";
import img_arrow from "../../../assets/expertise/Services Details Page (1)/Technology_3761631.svg";
import logo from "../../../assets/Group.svg";
import Studyban from "./Studyban";
function Semiconductor(props) {
  return (
    <div>
      <Scrolltop />
      <Header />
      <StickyHeader />
      <Studyban />
      <div className="bg-light-v row mx-auto">
        <div className="col-lg-10 col-md-11 mx-auto bg-white shadow py-5 web-solution">
          <div className="row mx-auto">
            <h6 class="p3 font-clr-green upper text-left col-12">Overview</h6>
            <div className="col-lg-8 col-md-8 text-left my-auto">
              <div className="para-d mt-2 mb-3 p-14">
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                  sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                  nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className="para-d mt-2 mb-3 p-14">
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, At vero eos et accusam et justo duo dolores et
                  ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                  est.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 bg-parrot d-flex br-8 unilogo">
              <img src={logo} className="w-50 m-auto white-filter" alt="loo" />
            </div>
            <div className="col-12 mt-5 p-0">
              <div className="row mx-auto">
                <div className="col-lg-4 col-md-4 mt-3">
                  <div className="d-flex border-right b-parrot">
                    <div>
                      <img src={img_1} height="45" alt="" />
                    </div>
                    <div className="text-left ml-xl-3 ml-md-2 ml-1">
                      <div className="p6 darker font-weight-bold mb-2">
                        Industry
                      </div>
                      <div className="p3 darker font-weight-light">
                        Retail and E-Commerce
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mt-3">
                  <div className="d-flex border-right b-parrot">
                    <div>
                      <img src={img_2} height="45" alt="" />
                    </div>
                    <div className="text-left l-xl-3 mx-md-2 ml-1">
                      <div className="p6 darker font-weight-bold mb-2">
                        Services
                      </div>
                      <div className="p3 darker font-weight-light">
                        Consumer Website
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mt-3">
                  <div className="d-flex parrot">
                    <div>
                      <img src={img_3} height="45" alt="" />
                    </div>
                    <div className="text-left ml-xl-3 ml-md-2 ml-1">
                      <div className="p6 darker font-weight-bold mb-2">
                        Technologies
                      </div>
                      <div className="p3 darker font-weight-light">
                        Docker, Magento
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 pb--80">
          <div className="row mx-auto text-left justify-content-center">
            <div className="col-lg-2 col-md-2">
              <div className="p6 mb-2 font-weight-bold">Challenge</div>
            </div>
            <div className="col-lg-8 col-md-8 mb-5">
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                As Head of Global and Commercial Learning and Development at
                Vodafone, Mohsin Ghafoor’s task was to raise standards in
                digital marketing, across the group.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Our aim was that every marketer at Vodafone reaches what we
                would describe as a minimum acceptable standard of digital
                marketing skills, knowledge, and capability, and be able to
                apply that to their day job and have an impact on our
                customers.”
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                But not only does Vodafone have a very large workforce, it is
                also spread all over the world. Natasha Brookes is a Learning
                and Development Specialist at Vodafone. For her, it was clear
                that a digital marketing course needed an online solution.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Vodafone is a large global organization. In order to reach all
                of the marketers within the different markets, what better way
                to do it than having one place where marketers can come and
                learn? And it will be a consistent learning approach.”
              </div>
            </div>
          </div>
          <div className="row mx-auto text-left justify-content-center">
            <div className="col-lg-2 col-md-2">
              <div className="p6 mb-2 font-weight-bold">Solution</div>
            </div>
            <div className="col-lg-8 col-md-8 mb-5">
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                As Head of Global and Commercial Learning and Development at
                Vodafone, Mohsin Ghafoor’s task was to raise standards in
                digital marketing, across the group.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Our aim was that every marketer at Vodafone reaches what we
                would describe as a minimum acceptable standard of digital
                marketing skills, knowledge, and capability, and be able to
                apply that to their day job and have an impact on our
                customers.”
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                But not only does Vodafone have a very large workforce, it is
                also spread all over the world. Natasha Brookes is a Learning
                and Development Specialist at Vodafone. For her, it was clear
                that a digital marketing course needed an online solution.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Vodafone is a large global organization. In order to reach all
                of the marketers within the different markets, what better way
                to do it than having one place where marketers can come and
                learn? And it will be a consistent learning approach.”
              </div>
            </div>
          </div>
          <div className="row mx-auto text-left justify-content-center">
            <div className="col-lg-2 col-md-2">
              <div className="p6 mb-2 font-weight-bold">Results</div>
            </div>
            <div className="col-lg-8 col-md-8 mb-5">
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                As Head of Global and Commercial Learning and Development at
                Vodafone, Mohsin Ghafoor’s task was to raise standards in
                digital marketing, across the group.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Our aim was that every marketer at Vodafone reaches what we
                would describe as a minimum acceptable standard of digital
                marketing skills, knowledge, and capability, and be able to
                apply that to their day job and have an impact on our
                customers.”
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                But not only does Vodafone have a very large workforce, it is
                also spread all over the world. Natasha Brookes is a Learning
                and Development Specialist at Vodafone. For her, it was clear
                that a digital marketing course needed an online solution.
              </div>
              <div className="p3 font-weight-lighter mb-xl-4 mb-2">
                “Vodafone is a large global organization. In order to reach all
                of the marketers within the different markets, what better way
                to do it than having one place where marketers can come and
                learn? And it will be a consistent learning approach.”
              </div>
            </div>
          </div>

          <div className="row mx-auto text-left justify-content-center">
            <div className="col-lg-10 col-md-10 mx-auto">
              <div className="row align-items-center">
                <div className="p3 mx-2 font-weight-lighter ">TAGS:</div>
                <div className="p3 mx-2 mt-2 font-weight-lighter border br-10 py-2 px-3">
                  #CLOUD
                </div>
                <div className="p3 mx-2 mt-2 font-weight-lighter border br-10 py-2 px-3">
                  #HEALCARE
                </div>
                <div className="p3 mx-2 mt-2 font-weight-lighter border br-10 py-2 px-3">
                  #INDUSTRY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-auto bg-light-v py-xxl-5 font-clr-green border-top">
        <div className="col-lg-12">
          <div className="d-flex">
            <div>
              <img src={img_arrow} height="45" alt="" />
            </div>
            <div className="text-left ml-xl-3 ml-md-2 ml-1">
              <p className="mb-0">PREVIOUS POST</p>
              <p className="font-weight-bold mb-0">
                Support Center For Semiconductor Provider
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Semiconductor;
