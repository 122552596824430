//Navbar.js
import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Dropdown,
  Button,
} from "react-bootstrap";
// import contact_icon from "../../assets/Icon ionic-ios-chatboxes.svg";
// import profile_icon from "../../assets/Group 15.svg";
import logo from "../../assets/logo.svg";
import Sidebar from "./sidebar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const title = "Business Developer";

function StickyHeader(props) {
  const [home, setHome] = useState(false);
  const [cases, setCases] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [about, setAbout] = useState(false);
  const [expertise, setExpertise] = useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const history = useHistory();

  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  const [showDropdown, setShowDropdown] = useState(false);
  return (
    /* rest remains same*/
    <Navbar expand="xl" sticky="top" className="main-navbar px-1">
      <div className="container-fluid">
        <Link to="/home" className="d-block d-xl-none mbl-logo">
          <img src={logo} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="row mx-auto resp-nav" id="basic-navbar-nav">
          {/* <div className="d-block d-xl-none sidebar-mb">
          <Sidebar />
        </div> */}
          <Nav className="col-xl-5 col-lg-12 mx-auto d-xl-block  navbar-nav px-0 navbar-nav text-left">
            <NavLink
              to="/home"
              isActive={(match, location) => {
                if (match) {
                  setHome(true);
                }
              }}
              activeClassName=""
              className={`darker green-hov font-16 
               text-center  ${
                 home
                   ? "link-active font-clr-green"
                   : "nav-hdr-link font-weight-lighter"
               } w-fit col-xl `}
            >
              HOME
            </NavLink>

            <NavLink
              to="/about"
              isActive={(match, location) => {
                if (match) {
                  setAbout(true);
                }
              }}
              activeClassName=""
              className={`darker green-hov font-16 
               text-center  ${
                 about
                   ? "link-active font-clr-green"
                   : "nav-hdr-link font-weight-lighter"
               } w-fit col-xl `}
              onMouseLeave={() => setShowDropdown(false)}
              onMouseOver={() => setShowDropdown(true)}
            >
              COMPANY{" "}
              {!show ? (
                <i className="fa fa-angle-down"></i>
              ) : (
                <fa className="fa fa-times font-color-green"></fa>
              )}
              {/* <Dropdown.Toggle className='main-style' id='dropdown-basic'>
                Dropdown Button
              </Dropdown.Toggle> */}
              <Dropdown.Menu show={showDropdown}>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  Who We Are
                </Dropdown.Item>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  Our Values
                </Dropdown.Item>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  Technologies
                </Dropdown.Item>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  Careers
                </Dropdown.Item>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  Meet Our Team
                </Dropdown.Item>
                <Dropdown.Item
                  className="align-items-center d-flex"
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon className="drop-arrow d-none" />
                  FAQ
                </Dropdown.Item>
              </Dropdown.Menu>
            </NavLink>
            <NavLink
              to="/expertise"
              isActive={(match, location) => {
                if (match) {
                  setExpertise(true);
                }
              }}
              activeClassName=""
              className={`darker green-hov font-16 text-center 
               ${
                 expertise
                   ? "link-active font-clr-green"
                   : "nav-hdr-link font-weight-lighter"
               } w-fit col-xl `}
            >
              EXPERTISE
            </NavLink>
            {/* <NavLink
              to='/header'
              isActive={(match, location) => {
                if (match) {
                  setIndustry(true)
                }
              }}
              activeClassName=''
              className={`darker green-hov font-16 text-center   ${
                industry
                  ? 'link-active font-clr-green'
                  : 'nav-hdr-link font-weight-lighter'
              } w-fit col-xl `}
            >
              INDUSTRIES
            </NavLink> */}
            <NavLink
              to="/casestudies"
              isActive={(match, location) => {
                if (match) {
                  setCases(true);
                }
              }}
              activeClassName=""
              className={`darker green-hov font-16 text-center   ${
                cases
                  ? "link-active font-clr-green"
                  : "nav-hdr-link font-weight-lighter"
              } w-fit col-xl`}
            >
              CASE STUDIES
            </NavLink>
          </Nav>
          <Link
            to="/home"
            className="d-xl-block d-none text-left col-xl-3 col-lg-2"
          >
            <img src={logo} className="header-logo" alt="logo" />
          </Link>
          <div className="col-xl-4 col-lg-3 px-0">
            <div className="row mx-auto d-xl-flex d-none justify-content-end">
              <div className="d-flex align-items-center font-16">
                <p className="font-weight-lighter darker mb-0 poppin-regular">
                  Now Hiring:
                </p>
                &nbsp; &nbsp;
                <a
                  href="/"
                  className="font-weight-bold h-n-link poppin-semibold"
                >
                  {title}
                </a>
              </div>
              <div className="d-flex ml-xl-4">
                <a
                  href="https://www.facebook.com/search/top?q=digimark%20developers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f stky-h-n-link text-dark mx-2"></i>
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram stky-h-n-link text-dark mx-2"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/digimarkdevelopers/mycompany/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in stky-h-n-link text-dark mx-2"></i>
                </a>
                <a
                  href="https://www.behance.net/digimarkdevelopers?tracking_source=search_users_recommended%7C%20developers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-behance stky-h-n-link text-dark mx-2 pr-xxl-3"></i>
                </a>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
export default StickyHeader;
