import React from "react";
import Marquee from "react-fast-marquee";
import Vocal from "../../../assets/vocal.png";
import MidFeed from "../../../assets/midfeed.png";
import Voxendo from "../../../assets/voxendo.png";
import PetLuvs from "../../../assets/petluvs.png";
import Sybal from "../../../assets/sybal.png";
import Etax from "../../../assets/etax.png";

function ClientsMarquee(props) {
  const clients = [{ path: "../../../assets/Group.svg" }];
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mx-auto">
          <div className="row mx-auto">
            <div className="col-lg-12 border-top mx-auto"></div>
            <div className="col-lg-12 mx-auto py-3 py-xl-4 py-lg-5 my-xl-2">
              <Marquee>
                <div className="d-flex align-items-center">
                  {/* {Array.from({ length: 20 }, (_, i) => (
                <img src={img} className='mx-5' />
              ))} */}
                  <img src={Vocal} className="mx-5" />
                  <img src={MidFeed} className="mx-5" />
                  <img src={Voxendo} className="mx-5" />
                  <img src={PetLuvs} className="mx-5" />
                  <img src={Sybal} className="mx-5" />
                  <img src={Etax} className="mx-5" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsMarquee;
