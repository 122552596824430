import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./WithScrollbar.css";
import Carousel2 from '../../../../assets/expertise/carousel2.png'
import Carousel1 from '../../../../assets/expertise/carousel1.png'
import Carousel3 from '../../../../assets/expertise/carousel3.png'
const CustomDot = ({ onClick, ...rest }) => {
  const {
    onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType }
  } = rest;
  const carouselItems = [1, 2, 3,4,5];
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <button
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    >
      {React.Children.toArray(carouselItems)[index]}
    </button>
  );
};
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class WithScrollbar extends React.Component {
  state = { additionalTransfrom: 0 };
  render() {
    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          // so that we don't over-slide
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100; // calculate the unit of transform for the slider
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider">
          {/* <input
            type="range"
            value={Math.round(Math.abs(transform) / value)}
            defaultValue={0}
            max={
              (carouselItemWidth *
                (carouselState.totalItems - carouselState.slidesToShow) +
                (this.state.additionalTransfrom === 150 ? 0 : 150)) /
              value
            }
            onChange={(e) => {
              if (this.Carousel.isAnimationAllowed) {
                this.Carousel.isAnimationAllowed = false;
              }
              const nextTransform = e.target.value * value;
              const nextSlide = Math.round(nextTransform / carouselItemWidth);
              if (
                e.target.value == 0 &&
                this.state.additionalTransfrom === 150
              ) {
                this.Carousel.isAnimationAllowed = true;
                this.setState({ additionalTransfrom: 0 });
              }
              this.Carousel.setState({
                transform: -nextTransform, // padding 20px and 5 items.
                currentSlide: nextSlide,
              });
            }}
            className="custom-slider__input"
          /> */}
        </div>
      );
    };
    return (
      <Carousel
        ssr={false}
        ref={(el) => (this.Carousel = el)}
        arrows={false} showDots={true} customDot={<CustomDot/>}
        partialVisbile={false}
        customButtonGroup={<CustomSlider />}
        itemClass="slider-image-item"
        responsive={responsive}
        containerClass="carousel-container-with-scrollbar"
        additionalTransfrom={-this.state.additionalTransfrom}
        beforeChange={(nextSlide) => {
          if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
            this.setState({ additionalTransfrom: 150 });
          }
          if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
            this.setState({ additionalTransfrom: 0 });
          }
        }}
      >
        <div class="image-container increase-size">
          <img
            draggable={false}
            style={{ width: "280px", cursor: "pointer" }}
            src={Carousel2}
          />
          <div class="image-container-text text-center">
          <h6 class='font-weight-bold font-clr-green upper mt-3'>
              WEBSITE
            </h6>
            <p class='p3 font-weight-bold mx-auto m-0'>
            FOOD ORDERING WEBSITE
            </p>
          </div>
        </div>
        <div class="image-container increase-size">
          <img
            draggable={false}
            style={{ width: "280px", cursor: "pointer" }}
            src={Carousel1}
          />
          <div class="image-container-text text-center">
          <h6 class='font-weight-bold font-clr-green upper mt-3'>
              WEBSITE
            </h6>
            <p class='p3 font-weight-bold mx-auto m-0'>
            ECOMMERCE WEBSITE
            </p>
          </div>
        </div>
        <div class="image-container increase-size">
          <img
            draggable={false}
            style={{ width: "280px", cursor: "pointer" }}
            src={Carousel3}
          />
          <div class="image-container-text text-center">
          <h6 class='font-weight-bold font-clr-green upper mt-3'>
              WEBSITE
            </h6>
            <p class='p3 font-weight-bold mx-auto m-0'>
            HR PORTAL DESIGN UX/UI
            </p>
          </div>
        </div>
        <div class="image-container increase-size">
          <img
            draggable={false}
            style={{ width: "280px", cursor: "pointer" }}
            src={Carousel2}
          />
          <div class="image-container-text text-center">
          <h6 class='font-weight-bold font-clr-green upper mt-3'>
              WEBSITE
            </h6>
            <p class='p3 font-weight-bold mx-auto m-0'>
            ECOMMERCE WEBSITE
            </p>
          </div>
        </div>
        <div class="image-container increase-size">
          <img
            draggable={false}
            style={{ width: "280px", cursor: "pointer" }}
            src={Carousel1}
          />
          <div class="image-container-text text-center">
          <h6 class='font-weight-bold font-clr-green upper mt-3'>
              WEBSITE
            </h6>
            <p class='p3 font-weight-bold mx-auto m-0'>
            HR PORTAL DESIGN UX/UI
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}

export default WithScrollbar;
