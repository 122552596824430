import React from "react";
import Slider from "infinite-react-carousel";
import Carousel from "react-bootstrap/Carousel";
import Web from "../../../assets/ic_web_development.png";
import WebGreen from "../../../assets/ic_web_development2.png";
import Mob from "../../../assets/ic_mobile_app_development.png";
import MobGreen from "../../../assets/ic_mobile_development2.png";
import Blockchain from "../../../assets/ic_blockchain_development.png";
import BlockchainGreen from "../../../assets/ic_blockchain2.png";
import "../../styles/carousel.css";
function ServicesCarousel(props) {
  return (
    <Carousel className="inner-h">
      <Carousel.Item className="">
        <div className="">
          <div className="display-cards">
            <div className="row">
              <div className="col-lg-4 col-md">
                <div className="card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    WEB DEVELOPMENT
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    Today's modish solution is an amalgamation of multiple
                    interconnected technologies. Everything we create is
                    purposefully designed to your specifications.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Web} alt="Card Back" class="img-top-1" />
                      <img src={WebGreen} class="img-top" alt="Card Front" />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-md-block d-lg-block col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    MOBILE APP DEVELOPMENT
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    We build digitally transform any business into an app that
                    can be monetized through purchasing a download. Our designed
                    App is full of security, and usability in mind.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Mob} alt="Card Back" class="img-top-1" />
                      <img src={MobGreen} class="img-top" alt="Card Front" />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-lg-block col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    BLOCKCHAIN DEVELOPMENT
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    Blockchains dominance the future. To develop
                    blockchain-based projects, we offer fully scalable, attract
                    high-class specialists, trendsetters, and visionaries in the
                    blockchain field
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="">
        <div className="">
          <div className="display-cards">
            <div className="row">
              <div className="col-lg-4 col-md">
                <div className="card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">CLOUD SERVICES</h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    Kick-start your transition journey to Cloud-based. We
                    empower businesses from strategic to creating secure,
                    uncluttered, highly efficient cloud solutions.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-md-block d-lg-block col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    TECHNOLOGY CONSULTING
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    We help our customers to choose the right technology for
                    their requirements and system integration as well as advise
                    on improvements to your digital customer experience.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-lg-block col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    QUALITY ASSURANCE
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    DigiMark Developers, take full commitment to the success of
                    the application. QA automation reduces costs and provides
                    confidence in 100% quality solutions.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className="">
        <div className="">
          <div className="display-cards">
            <div className="row">
              <div className="col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">
                    DIGITAL MARKETING
                  </h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    Is your business new to internet marketing? We offer
                    full-service online branding which includes search engine
                    optimization and marketing services such as PPC ad
                    management.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-lg-block col-lg-4 col-md">
                <div className=" card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 ">
                  <h4 className="sub-black-heading mt-xxl-5">BRANDING</h4>
                  <p className="my-xxl-3 align-items-center lh-2 description">
                    By working with you in pre-determined checkpoints, we turn
                    ideas into solid visual presentations to promote product
                    ideas, taking feedback from participants along the way.
                  </p>
                  <div>
                    <div className="svgs-imgs">
                      <img src={Blockchain} alt="Card Back" class="img-top-1" />
                      <img
                        src={BlockchainGreen}
                        class="img-top"
                        alt="Card Front"
                      />
                    </div>
                  </div>
                  <button className="card-d-btn p-0">
                    <div className="row px-2 align-items-center card-link-heading">
                      <p className="m-0 font-weight-bold">
                        STAY UP AND RUNNING
                      </p>
                      <i class="fas fa-arrow-right v-align mx-2 py-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-none d-md-block d-lg-block col-lg-4 col-md"></div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ServicesCarousel;
