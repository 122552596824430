import React from "react";
import whatwedo from "../../../assets/About Us Page/Group 3787.svg";
import believe from "../../../assets/About Us Page/Group 3788.svg";
function Whatwedo(props) {
  return (
    <div className="bg-light-v row mx-auto py--80">
      <div className="col-lg-12">
        <div className="">
          <div className="row mx-auto text-left align-items-center">
            <div className="col-md-6 col-lg-6 mb-5">
              <div className="p4 darker font-weight-bold mb-4">What We Do</div>
              <div className="para-d description">
                DigiMark Developers is an international agency providing design
                and build bespoke digital solutions for businesses world-wide.
                From local businesses to international corporations, DigiMark
                Developers has worked with organizations of all sizes to create
                solutions to meet their business needs. Our agency is comprised
                of flexible teams that can be scaled up or down to meet the
                demands of any business for any project at any time. Our vision
                is to constantly improve mobile apps and the web and grow as a
                great mobile app and web design and development company.
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-5">
              <img src={whatwedo} width="100%" alt="whatwedo_img" />
            </div>
          </div>
          <div className="row mx-auto text-left align-items-center">
            <div className="col-md-6 col-lg-6 mb-5">
              <img src={believe} width="90%" alt="believe_img" />
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="p4 darker font-weight-bold mb-4">
                What We Believe
              </div>
              <div className="para-d description">
                We work to create solutions that meet the objectives of the
                business and provide a fantastic experience for your users. By
                combining both programming experts with marketing consultants,
                the DigiMark Developers team offers its clients a unique
                combination of skills and abilities. Our virtual agency’s
                creativity, innovation, and skills represent the most promising
                talent from around the world. Our staff is dedicated to the
                DigiMark Developers goal: a better tomorrow for your business.
                We also conduct workshops and rehabilitation programs for our
                employees and keep support in their self-growth.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whatwedo;
