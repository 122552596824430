import React from "react";
import img from "../../../assets/Group 47.png";
function CaseStudies(props) {
  return (
    <div className="bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mx-auto pb-3 mb-2 pt--80">
            <h5 className="green-heading">WHO WE SERVE</h5>
            <div className="font-clr-blue row mx-0">
              <div className="col-lg-12 col-md-10 col-12 mx-auto">
                <h1 className="black-heading mx-auto">
                  Eliminate the Digital Challenges Your Business
                </h1>
              </div>
              <div className="mt-3 descriptionWhoWeServe mx-auto">
                Our vertical solutions expertise allows your business to
                streamline workflow, and increase productivity. No matter the
                business, Digimark Developers has you covered.
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 mt-3">
            <a href="">
              {" "}
              <div class="card serve-card">
                <div className="img-container">
                  <img
                    src={img}
                    height="180 px"
                    className="card-img-top no-radius"
                    alt="Snow"
                  />
                  <div class="centered serve-card-heading mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pb-0">
                  <div className="">
                    <h6 className="green-heading">Cyber Security</h6>
                    <h5 className="mt-0 mb-2 mx-auto sub-black-heading font-clr-blu">
                      Support center for semiconductor provider
                    </h5>
                    <p class="description mb-3 pb-3 font-clr-blu">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan  hov-btn-color poppin-regular font-weight-lighter btn-block">
                        <span className="d-block lh-1 pt-xxl-1 serve-card-btn">
                          DISCOVER HOW WE HELP
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-6 col-md-6 mt-3">
            <a href="">
              <div class="card serve-card">
                <div className="img-container">
                  <img
                    src={img}
                    height="180 px"
                    className="card-img-top no-radius"
                    alt="Snow"
                  />
                  <div class="centered serve-card-heading mx-auto">
                    Digimark Developers
                  </div>
                </div>
                <div class="card-body text-left pb-0">
                  <div className="">
                    <h6 className="green-heading">Cyber Security</h6>
                    <h5 className="mt-0 mb-2 mx-auto sub-black-heading font-clr-blu">
                      Support center for semiconductor provider
                    </h5>
                    <p class="description mb-3 pb-3 font-clr-blu">
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className="d-block align-items-center green-hov-child svg">
                      <button className="button button--pan  hov-btn-color poppin-regular font-weight-lighter btn-block">
                        <span className="d-block lh-1 pt-xxl-1 serve-card-btn">
                          DISCOVER HOW WE HELP
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12 mx-auto short-heading">
            <p className="link-heading">
              Find out how companies succeed with us.
              <a
                href="#"
                target="_blank"
                className="h-n-link darker font-weight-bold"
              >
                Read our case studies
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudies;
