import React, { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "../styles/animate.css";
import { Link, NavLink } from "react-router-dom";
import contact_icon from "../../assets/Icon ionic-ios-chatboxes.svg";
import profile_icon from "../../assets/Group 15.svg";
import logo from "../../assets/dm-logo-landscape-1.png";
import Sidebar from "./sidebar";

function Header(props) {
  const [home, setHome] = useState(false);
  const [cases, setCases] = useState(false);
  return (
    <>
      <div>
        <div className="container-fluid bg-seconday">
          <div className="mx-auto row py-header" id="header">
            <div className="text-white text-left col-lg-12 padding-sm px-0">
              <div className="row align-items-center mx-auto justify-content-center topbar-header">
                <div className="col-lg-6 padding-sm">
                  <div className="d-flex align-items-center justify">
                    <div className="contact">
                      <div className="d-flex top-header align-items-center">
                        <img src={contact_icon} className="h-desktop" alt="" />
                        <a
                          href="tel:+1 4073734745"
                          className="ml-xl-3 ml-2 green-hov white"
                        >
                          <div className="heading mb-0">
                            <p>Contact us</p>
                          </div>
                          <div className="mb-0 description">
                            <p>+1 4073734745</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="contact  ml-xl-5 ml-4">
                      <div className="d-flex top-header align-items-center">
                        <img src={profile_icon} className="h-desktop" alt="" />
                        <a
                          href="mailto:info@digimarkdevelopers.com"
                          className="ml-xl-3 ml-2 green-hov white"
                        >
                          <div className="heading">
                            <p>Have a question?</p>
                          </div>
                          <div className="mb-0 description">
                            <p>info@digimarkdevelopers.com</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 padding-sm">
                  <div className="ml-auto text-right d-lg-block d-none career-blog">
                    <div className="d-flex mx-auto align-items-center justify-content-end">
                      <div className="d-block align-items-center green-hov-child svg mx-2 mx-xl-4">
                        <a
                          href="/about#careers"
                          className="d-block white text-center"
                        >
                          <svg
                            id="noun_career_2254249"
                            className=""
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-32"
                            viewBox="0 0 37.812 37.812"
                          >
                            <path
                              id="Path_3"
                              data-name="Path 3"
                              d="M0,57.162a1.774,1.774,0,0,0,1.772,1.772H7.681a1.772,1.772,0,1,0,0-3.545H4.3a1.826,1.826,0,0,0,1.918-1.444A1.69,1.69,0,0,0,5.931,52.6a1.4,1.4,0,0,0-.471-.4l1.358-4.82L7.026,51.4a.75.75,0,0,0,.849.738h.067L10.1,52.13A1.523,1.523,0,0,0,11.52,50.6a1.506,1.506,0,0,0-.942-1.464l.093-2.962a1.255,1.255,0,0,0-.052-.328c-.014-.049-.038-.131-1.359-3.078L9.3,41.729l4.008-2.562a1.242,1.242,0,0,0,.378-1.716l-.443-.69a1.253,1.253,0,0,0-1.718-.378l-2.064,1.3.032-.79a3.17,3.17,0,0,0-.888-2.275,2.664,2.664,0,0,0-.44-.352A2.364,2.364,0,1,0,5.2,34.083a3.109,3.109,0,0,0-1.946,2.856l.064,5.128a1.7,1.7,0,0,0,.182.708L1.11,53.617c-.016.046-.035.095-.054.144a1.2,1.2,0,0,0-.038,1.134.8.8,0,0,0,.668.351l2.4.136c.066.006.133.008.2.008H1.772A1.774,1.774,0,0,0,0,57.162Zm6.794-26a1.182,1.182,0,1,1-1.182,1.182A1.182,1.182,0,0,1,6.794,31.166Zm-.329,3.841h.128a1.636,1.636,0,0,1,1.182.453,1.984,1.984,0,0,1,.538,1.413l-.023.567-.225-.227a.591.591,0,1,0-.84.831l1.159,1.172a.592.592,0,0,0,.735.084l3.037-1.918a.068.068,0,0,1,.093.017l.443.69a.054.054,0,0,1,.008.042.061.061,0,0,1-.028.04L8.425,40.886a.287.287,0,0,1-.3-.034L6.031,38.758a.591.591,0,1,0-.836.835l2.091,2.094a1.416,1.416,0,0,0,.82.389L8.068,43l.057.127c.48,1.072,1.255,2.807,1.362,3.065l0,.007-.122,3.869.517.079c.019,0,.468.076.455.412-.006.16-.1.389-.264.395l-1.889.005L7.94,46.214a1.14,1.14,0,0,0-.262-.684l-3.1-3.224a.613.613,0,0,1-.085-.25l-.064-5.127a1.989,1.989,0,0,1,2.034-1.922Zm-2.3,19.2-1.971-.111c.017-.046.034-.092.048-.135L4.46,43.886l1.635,1.7-2.132,7.57.873.073a.205.205,0,0,1,.153.086.523.523,0,0,1,.063.405c-.095.492-.641.5-.887.481Zm3.515,2.369a.591.591,0,1,1,0,1.182H1.772a.591.591,0,1,1,0-1.182Z"
                              transform="translate(0 -21.123)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_4"
                              data-name="Path 4"
                              d="M75.77,18.157a1.182,1.182,0,0,0,1.182-1.182v-2A1.182,1.182,0,0,0,75.77,13.8h-.377a6.661,6.661,0,0,0-.338-.815l.267-.267a1.182,1.182,0,0,0,0-1.671L73.909,9.63a1.182,1.182,0,0,0-1.671,0l-.267.267a6.66,6.66,0,0,0-.815-.338V9.182A1.182,1.182,0,0,0,69.975,8h-2A1.182,1.182,0,0,0,66.8,9.182v.377a6.661,6.661,0,0,0-.815.338l-.267-.267a1.182,1.182,0,0,0-1.671,0L62.63,11.043a1.182,1.182,0,0,0,0,1.671l.267.267a6.663,6.663,0,0,0-.338.815h-.377A1.182,1.182,0,0,0,61,14.977v2a1.182,1.182,0,0,0,1.182,1.182h.377a6.66,6.66,0,0,0,.338.815l-.267.267a1.182,1.182,0,0,0,0,1.671l1.413,1.413a1.182,1.182,0,0,0,1.671,0l.267-.267a6.659,6.659,0,0,0,.815.338v.377a1.182,1.182,0,0,0,1.182,1.182h2a1.182,1.182,0,0,0,1.182-1.182v-.377a6.661,6.661,0,0,0,.815-.338l.267.267a1.182,1.182,0,0,0,1.671,0l1.413-1.413a1.182,1.182,0,0,0,0-1.671l-.267-.267a6.66,6.66,0,0,0,.338-.815Zm-1.284,1.917-1.413,1.413-.912-.912a5.563,5.563,0,0,1-2.187.906v1.29h-2v-1.29a5.563,5.563,0,0,1-2.187-.906l-.912.912-1.413-1.413.912-.912a5.563,5.563,0,0,1-.906-2.187h-1.29v-2h1.29a5.563,5.563,0,0,1,.906-2.187l-.912-.912,1.413-1.413.912.912a5.563,5.563,0,0,1,2.187-.906V9.182h2v1.29a5.563,5.563,0,0,1,2.187.906l.912-.912,1.413,1.413-.912.912a5.563,5.563,0,0,1,.906,2.187h1.29v2h-1.29a5.563,5.563,0,0,1-.906,2.187Z"
                              transform="translate(-42.98 -5.637)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_5"
                              data-name="Path 5"
                              d="M81.5,22.068h-.591v-.886A1.182,1.182,0,0,0,79.727,20H77.954a1.182,1.182,0,0,0-1.182,1.182v.886h-.591A1.182,1.182,0,0,0,75,23.249v3.545a1.182,1.182,0,0,0,1.182,1.182H81.5a1.182,1.182,0,0,0,1.182-1.182V23.249A1.182,1.182,0,0,0,81.5,22.068Zm-3.545-.886h1.772v.886H77.954ZM81.5,26.794H76.182V25.613H81.5Zm0-2.363H76.182V23.249H81.5Z"
                              transform="translate(-52.844 -14.092)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_6"
                              data-name="Path 6"
                              d="M53.655,73H47.746a1.771,1.771,0,0,0-1.241,3.036,1.766,1.766,0,0,0-.531-.082H40.066a1.772,1.772,0,0,0,0,3.545H34.453a1.771,1.771,0,0,0-1.241,3.036,1.766,1.766,0,0,0-.531-.082H26.772a1.772,1.772,0,0,0,0,3.545h5.908a1.771,1.771,0,0,0,1.241-3.036,1.766,1.766,0,0,0,.531.082h5.908a1.772,1.772,0,0,0,0-3.545h5.613a1.771,1.771,0,0,0,1.241-3.036,1.765,1.765,0,0,0,.531.082h5.908a1.772,1.772,0,0,0,0-3.545ZM32.681,84.816H26.772a.591.591,0,0,1,0-1.182h5.908a.591.591,0,0,1,0,1.182Zm8.271-3.545a.591.591,0,0,1-.591.591H34.453a.591.591,0,1,1,0-1.182h5.908A.591.591,0,0,1,40.952,81.271Zm5.022-2.954H40.066a.591.591,0,0,1,0-1.182h5.908a.591.591,0,0,1,0,1.182Zm7.681-2.954H47.746a.591.591,0,0,1,0-1.182h5.908a.591.591,0,0,1,0,1.182Z"
                              transform="translate(-17.615 -51.435)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_7"
                              data-name="Path 7"
                              d="M74.088,20.383V1.772A1.774,1.774,0,0,0,72.315,0H55.772A1.774,1.774,0,0,0,54,1.772V27.018a2.943,2.943,0,0,1,.886-.136h.3V1.772a.591.591,0,0,1,.591-.591H72.315a.591.591,0,0,1,.591.591V20.383Z"
                              transform="translate(-38.048)"
                              fill="#d2d2d2"
                            />
                          </svg>
                          <div className="mb-0 mt-1">
                            <p>Careers</p>
                          </div>
                        </a>
                      </div>
                      <div className="d-block align-items-center green-hov-child svg mx-2 mx-xl-4 mx-xxl-4">
                        <a
                          href="/home#blogs"
                          className="d-block white text-center"
                        >
                          <svg
                            id="noun_Blog_1947498"
                            fill="#FFFFF"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-32"
                            viewBox="0 0 36.902 36.902"
                          >
                            <path
                              id="Path_27"
                              data-name="Path 27"
                              d="M36.442,10v1.23a1.23,1.23,0,0,1,1.23,1.23V30.911H3.23V12.46a1.23,1.23,0,0,1,1.23-1.23V10A2.46,2.46,0,0,0,2,12.46V33.371a2.46,2.46,0,0,0,2.46,2.46H16.549l-1.476,4.92H13.686v1.23H27.216v-1.23H25.829l-1.476-4.92H36.442a2.46,2.46,0,0,0,2.46-2.46V12.46A2.46,2.46,0,0,0,36.442,10Zm-11.9,30.751H16.357l1.476-4.92h5.235Zm13.127-7.38a1.23,1.23,0,0,1-1.23,1.23H4.46a1.23,1.23,0,0,1-1.23-1.23v-1.23H37.672Z"
                              transform="translate(-2 -5.08)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_28"
                              data-name="Path 28"
                              d="M37.521,26.6V4.46A2.46,2.46,0,0,0,35.061,2H10.46A2.46,2.46,0,0,0,8,4.46V26.6H9.23V4.46a1.23,1.23,0,0,1,1.23-1.23h24.6a1.23,1.23,0,0,1,1.23,1.23V26.6Z"
                              transform="translate(-4.31 -2)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_3"
                              data-name="Rectangle 3"
                              width="0.908"
                              height="1.815"
                              transform="translate(6.651 2.112)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_4"
                              data-name="Rectangle 4"
                              width="0.908"
                              height="1.815"
                              transform="translate(9.374 2.112)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_5"
                              data-name="Rectangle 5"
                              width="0.908"
                              height="1.815"
                              transform="translate(11.189 2.112)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_6"
                              data-name="Rectangle 6"
                              width="23.6"
                              height="1.815"
                              transform="translate(6.651 4.835)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_29"
                              data-name="Path 29"
                              d="M18.765,20.15a.615.615,0,0,0,.615-.615v-4.92A.615.615,0,0,0,18.765,14h-6.15a.615.615,0,0,0-.615.615v4.92a.615.615,0,0,0,.615.615ZM13.23,15.23h4.92v3.69H13.23Z"
                              transform="translate(-5.85 -6.62)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_7"
                              data-name="Rectangle 7"
                              width="4.538"
                              height="1.815"
                              transform="translate(14.82 7.558)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_8"
                              data-name="Rectangle 8"
                              width="9.985"
                              height="0.908"
                              transform="translate(14.82 10.282)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_9"
                              data-name="Rectangle 9"
                              width="4.538"
                              height="0.908"
                              transform="translate(14.82 12.097)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_10"
                              data-name="Rectangle 10"
                              width="23.6"
                              height="0.908"
                              transform="translate(6.651 14.82)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_11"
                              data-name="Rectangle 11"
                              width="4.538"
                              height="0.908"
                              transform="translate(25.712 10.282)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_12"
                              data-name="Rectangle 12"
                              width="8.169"
                              height="0.908"
                              transform="translate(21.174 12.097)"
                              fill="#d2d2d2"
                            />
                            <path
                              id="Path_30"
                              data-name="Path 30"
                              d="M12.615,30a.615.615,0,0,0-.615.615v4.92a.615.615,0,0,0,.615.615h6.15a.615.615,0,0,0,.615-.615v-4.92A.615.615,0,0,0,18.765,30Zm5.535,4.92H13.23V31.23h4.92Z"
                              transform="translate(-5.85 -12.779)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_13"
                              data-name="Rectangle 13"
                              width="4.538"
                              height="0.908"
                              transform="translate(14.82 17.543)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_14"
                              data-name="Rectangle 14"
                              width="9.985"
                              height="1.815"
                              transform="translate(14.82 19.359)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_15"
                              data-name="Rectangle 15"
                              width="4.538"
                              height="1.815"
                              transform="translate(14.82 22.082)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_16"
                              data-name="Rectangle 16"
                              width="4.538"
                              height="1.815"
                              transform="translate(25.712 19.359)"
                              fill="#d2d2d2"
                            />
                            <rect
                              id="Rectangle_17"
                              data-name="Rectangle 17"
                              width="8.169"
                              height="1.815"
                              transform="translate(21.174 22.082)"
                              fill="#d2d2d2"
                            />
                          </svg>
                          <div className="mb-0 mt-1">
                            <p>Blog</p>
                          </div>
                        </a>
                      </div>
                      <div className="d-block align-items-center green-hov-child svg mx-2 mx-xl-4">
                        <Link
                          to="/career"
                          className="d-block white text-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-32"
                            viewBox="0 0 37.228 24.241"
                          >
                            <path
                              id="Path_31"
                              data-name="Path 31"
                              d="M9.638,974.362A2.63,2.63,0,0,0,7,976.959v19.047a2.63,2.63,0,0,0,2.638,2.6H41.59a2.63,2.63,0,0,0,2.638-2.6V976.959a2.63,2.63,0,0,0-2.638-2.6H9.638Zm.446,1.731H41.143L26.371,989.3a1.285,1.285,0,0,1-1.515,0l-14.772-13.2ZM8.732,977.2l10.808,9.66L8.732,995.815Zm33.764,0v18.614l-10.808-8.954ZM20.852,988.038l2.854,2.543a2.87,2.87,0,0,0,3.815,0l2.854-2.543,10.673,8.833H10.179l10.673-8.832Z"
                              transform="translate(-7 -974.362)"
                              fill="#d2d2d2"
                            />
                          </svg>
                          <div className="mb-0 mt-1">
                            <p>Contact Us</p>
                          </div>
                        </Link>
                      </div>
                      <div className="d-block align-items-center green-hov-child svg ml-2 ml-xl-4 mt-2">
                        <a
                          href="/home#qoute"
                          className="button-1 header-btn text-white button--pan-1"
                        >
                          <span className="text-white text-center d-block">
                            REQUEST A QUOTE
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {/* <!-- Button animation zoom-in zoom-out infinite times --> */}

        {/* <div className="zoom-in-zoom-out mx-auto mt-5 ">
          <i className="fas fa-play text-white p-3 btn1 btn-animated"></i>
        </div> */}

        {/* <!-- Button to change background on hover --> */}
      </div>
    </>
  );
}

export default Header;
